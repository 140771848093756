import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  MultiSelect,
} from '@mantine/core';
import {
  SpecialtyFields,
  FormValues,
  specialties,
  nonPhysicianSpecialties,
} from '../types/specialties.types';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import CustomDateInput from './CustomDateInput';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';

interface FormProps {
  memberId: number;
}

const SpecialtyForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<FormValues>({
    defaultValues: {
      primaryspecialty: {
        memberId: memberId,
        type: 'primary',
        specialty: null,
        subspecialty: [],
        boardcertified: 'No',
        nonphysician: 'No',
        certificationnumber: '',
        certificationexpiration: null,
        recertificationdate: null,
        initialcertificationdate: null,
        certifyingboard: '',
      },
      secondaryspecialties: [],
    },
    mode: 'onChange',
  });

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secondaryspecialties',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<FormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const httpClient = useHttpClient();

  const fetchSpecialties = async () => {
    try {
      const response = await httpClient.get(`/api/specialties/${memberId}`);
      const fetchedSpecialties: SpecialtyFields[] = response.data;

      console.log('fetchedSpecialties', fetchedSpecialties);

      if (fetchedSpecialties.length === 0) {
        console.log('No specialties found for this member.');
        return;
      }

      const primarySpecialty = fetchedSpecialties.find(
        (s) => s.type === 'primary',
      );

      // Format the primary specialty to include both main specialty and subspecialties
      if (primarySpecialty) {
        const allSpecialties = [
          primarySpecialty.specialty,
          ...primarySpecialty.subspecialty.map((sub) => sub), // Just use subspecialties as they are
        ];

        primarySpecialty.specialty = allSpecialties[0];
        primarySpecialty.subspecialty = allSpecialties.slice(1);
      }

      const secondarySpecialties = fetchedSpecialties
        .filter((s) => s.type === 'secondary')
        .sort((a, b) => a.id! - b.id!);

      const resetData = {
        primaryspecialty: primarySpecialty || {
          memberId: memberId,
          type: 'primary',
          specialty: null,
          subspecialty: [],
          nonphysician: '',
          boardcertified: '',
          certificationnumber: '',
          certificationexpiration: null,
          recertificationdate: null,
          initialcertificationdate: null,
          certifyingboard: '',
        },
        secondaryspecialties: secondarySpecialties,
      };

      reset(resetData, {
        keepDirty: false,
        keepValues: false,
      });

      setInitialData({
        primaryspecialty: primarySpecialty!,
        secondaryspecialties: secondarySpecialties,
      });
    } catch (error) {
      console.error('Error fetching specialties:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch specialties',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    fetchSpecialties();
  }, [memberId, isSubmitting]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: FormValues) => {
    setIsSubmitting(true);
    console.log('submit form values', data);

    const createSpecialty = async (specialty: SpecialtyFields) => {
      const response = await httpClient.post('/api/specialties', specialty);
      return response.data;
    };

    const updateSpecialty = async (specialty: SpecialtyFields) => {
      const response = await httpClient.put(
        `/api/specialties/${specialty.id}`,
        specialty,
      );
      return response.data;
    };

    const deleteSpecialty = async (id: number) => {
      await httpClient.delete(`/api/specialties/${id}`);
      console.log('delete response');
    };

    const initialSecondaryIds = new Set(
      initialData?.secondaryspecialties.map((s) => s.id),
    );
    const currentSecondaryIds = new Set(
      data.secondaryspecialties.map((s) => s.id),
    );
    const deletedIds = [...initialSecondaryIds].filter(
      (id) => !currentSecondaryIds.has(id),
    );

    const primaryTask =
      !data.primaryspecialty.id || data.primaryspecialty.id === 0
        ? createSpecialty(data.primaryspecialty)
        : updateSpecialty(data.primaryspecialty);

    const secondaryCreationTasks = data.secondaryspecialties
      .filter((s) => !s.id || s.id === 0)
      .map((s) => createSpecialty(s));
    const secondaryUpdateTasks = data.secondaryspecialties
      .filter((s) => s.id && s.id > 0 && initialSecondaryIds.has(s.id))
      .map((s) => updateSpecialty(s));

    const secondaryDeleteTasks = deletedIds.map(deleteSpecialty);

    try {
      const results = await Promise.all([
        primaryTask,
        ...secondaryCreationTasks,
        ...secondaryUpdateTasks,
        ...secondaryDeleteTasks,
      ]);
      console.log('Results:', results);
      fetchSpecialties();

      notifications.show({
        title: 'Success',
        message: 'Specialties updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting specialty form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit specialty',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md">
          <Group>
            <h3>Primary Specialty</h3>
          </Group>
          <Grid>
            <Grid.Col span={8}>
              <Controller
                name="primaryspecialty.specialty"
                control={control}
                render={({ field: { onChange, ...field } }) => {
                  const mainSpecialty = field.value?.split(' - ')[0];
                  const hasSubspecialty = field.value?.includes(' - ');

                  // Deduplicate the values array
                  const values = [
                    ...new Set([
                      field.value,
                      ...watch('primaryspecialty.subspecialty').map(
                        (sub) => `${mainSpecialty} - ${sub}`,
                      ),
                    ]),
                  ].filter(Boolean);

                  return (
                    <MultiSelect
                      {...field}
                      value={values}
                      label="Specialty"
                      searchable
                      clearable
                      data={[
                        ...[
                          ...(hasSubspecialty
                            ? []
                            : mainSpecialty
                              ? [mainSpecialty]
                              : [
                                  ...Object.keys(specialties),
                                  ...Object.keys(nonPhysicianSpecialties),
                                ]),
                          ...Object.entries(specialties)
                            .flatMap(([specialty, subs]) =>
                              subs.map((sub) => `${specialty} - ${sub}`),
                            )
                            .filter(
                              (option) =>
                                !mainSpecialty ||
                                option.startsWith(mainSpecialty),
                            ),
                          ...Object.entries(nonPhysicianSpecialties)
                            .flatMap(([specialty, subs]) =>
                              subs.map((sub) => `${specialty} - ${sub}`),
                            )
                            .filter(
                              (option) =>
                                !mainSpecialty ||
                                option.startsWith(mainSpecialty),
                            ),
                        ].filter(
                          (option, index, self) =>
                            self.indexOf(option) === index,
                        ),
                      ]}
                      onChange={(values) => {
                        if (values.length === 0) {
                          setValue('primaryspecialty.specialty', null);
                          setValue('primaryspecialty.subspecialty', []);
                          return;
                        }

                        const mainValue = values[0];
                        setValue('primaryspecialty.specialty', mainValue);

                        const subspecialties = [
                          ...new Set(
                            values
                              .slice(1)
                              .filter((v) => v.includes(' - '))
                              .map((v) => v.split(' - ')[1]),
                          ),
                        ];
                        setValue(
                          'primaryspecialty.subspecialty',
                          subspecialties,
                        );
                      }}
                    />
                  );
                }}
              />
            </Grid.Col>
          </Grid>
          <Controller
            name="primaryspecialty.boardcertified"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Board Certified?"
                data={[
                  { value: 'N', label: 'No' },
                  { value: 'Y', label: 'Yes' },
                ]}
                clearable
              />
            )}
          />
          {watch('primaryspecialty.boardcertified') === 'Y' && (
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="Certification Number"
                  {...register('primaryspecialty.certificationnumber')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  control={control}
                  name="primaryspecialty.initialcertificationdate"
                  label="Initial Certification Date"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  control={control}
                  name="primaryspecialty.recertificationdate"
                  label="Last Recertification Date (If Applicable)"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  control={control}
                  name="primaryspecialty.certificationexpiration"
                  label="Expiration Date (If Applicable)"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Certifying Board"
                  {...register('primaryspecialty.certifyingboard')}
                />
              </Grid.Col>
            </Grid>
          )}
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" mt="10" withBorder>
            <Group>
              <h3>Secondary Specialty {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={8}>
                  <Controller
                    name={`secondaryspecialties.${index}.specialty`}
                    control={control}
                    render={({ field: { onChange, ...field } }) => {
                      const mainSpecialty = field.value?.split(' - ')[0];
                      const hasSubspecialty = field.value?.includes(' - ');

                      // Deduplicate the values array
                      const values = [
                        ...new Set([
                          field.value,
                          ...watch(
                            `secondaryspecialties.${index}.subspecialty`,
                          ).map((sub) => `${mainSpecialty} - ${sub}`),
                        ]),
                      ].filter(Boolean);

                      return (
                        <MultiSelect
                          {...field}
                          value={values}
                          label="Specialty"
                          searchable
                          clearable
                          data={[
                            ...[
                              ...(hasSubspecialty
                                ? []
                                : mainSpecialty
                                  ? [mainSpecialty]
                                  : [
                                      ...Object.keys(specialties),
                                      ...Object.keys(nonPhysicianSpecialties),
                                    ]),
                              ...Object.entries(specialties)
                                .flatMap(([specialty, subs]) =>
                                  subs.map((sub) => `${specialty} - ${sub}`),
                                )
                                .filter(
                                  (option) =>
                                    !mainSpecialty ||
                                    option.startsWith(mainSpecialty),
                                ),
                              ...Object.entries(nonPhysicianSpecialties)
                                .flatMap(([specialty, subs]) =>
                                  subs.map((sub) => `${specialty} - ${sub}`),
                                )
                                .filter(
                                  (option) =>
                                    !mainSpecialty ||
                                    option.startsWith(mainSpecialty),
                                ),
                            ].filter(
                              (option, index, self) =>
                                self.indexOf(option) === index,
                            ),
                          ]}
                          onChange={(values) => {
                            if (values.length === 0) {
                              setValue(
                                `secondaryspecialties.${index}.specialty`,
                                null,
                              );
                              setValue(
                                `secondaryspecialties.${index}.subspecialty`,
                                [],
                              );
                              return;
                            }

                            const mainValue = values[0];
                            setValue(
                              `secondaryspecialties.${index}.specialty`,
                              mainValue,
                            );

                            const subspecialties = [
                              ...new Set(
                                values
                                  .slice(1)
                                  .filter((v) => v.includes(' - '))
                                  .map((v) => v.split(' - ')[1]),
                              ),
                            ];
                            setValue(
                              `secondaryspecialties.${index}.subspecialty`,
                              subspecialties,
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid.Col>
              </Grid>
              <Controller
                name={`secondaryspecialties.${index}.boardcertified`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Board Certified?"
                    data={[
                      { value: 'Y', label: 'Yes' },
                      { value: 'N', label: 'No' },
                    ]}
                    clearable
                  />
                )}
              />
              {watch(`secondaryspecialties.${index}.boardcertified`) ===
                'Y' && (
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Certification Number"
                      {...register(
                        `secondaryspecialties.${index}.certificationnumber`,
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`secondaryspecialties.${index}.initialcertificationdate`}
                      label="Initial Certification Date"
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`secondaryspecialties.${index}.recertificationdate`}
                      label="Last Recertification Date (If Applicable)"
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`secondaryspecialties.${index}.certificationexpiration`}
                      label="Expiration Date (If Applicable)"
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Certifying Board"
                      {...register(
                        `secondaryspecialties.${index}.certifyingboard`,
                      )}
                    />
                  </Grid.Col>
                </Grid>
              )}
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              append({
                id: 0,
                memberId: memberId,
                type: 'secondary',
                nonphysician: 'No',
                specialty: null,
                subspecialty: [],
                boardcertified: 'No',
                certificationnumber: '',
                certificationexpiration: null,
                recertificationdate: null,
                initialcertificationdate: null,
                certifyingboard: '',
              })
            }
          >
            Add Secondary Specialty
          </Button>
        </Group>

        <Group mt="md">
          <Button type="submit" loading={isSubmitting}>
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default SpecialtyForm;
