import { useState, useMemo } from 'react';
import { Grid, Text, Paper, Container, Title, Timeline, Loader } from '@mantine/core';
import useHttpClient from './hooks/useHttpClient';

import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
export default function WecareMemberDashboard({ path }) {
  const computedColorScheme = useComputedColorScheme('light');
  const httpClient = useHttpClient();
  const currentDay = new Date().toLocaleDateString(undefined, { weekday: 'long' });

  const [userInfo, setUserInfo] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any>();

  const fetchUserInformation = async () => {
    setIsloading(true);
    try {
      const { data } = await httpClient.get(`/api/members`);
      setUserInfo(data);
      const { data: userMetadata } = await httpClient.get(`/api/user/metadata`);
      setMetadata(userMetadata);
    } catch (error) {
      console.error('failed to fetch user information', error);
    } finally {
      setIsloading(false);
    }
  };

  useMemo(() => {
    fetchUserInformation();
  }, []);

  return (
    <Container size="xl">
      <Paper withBorder shadow="none" p={0} radius="md">
        <Grid>
          <Grid.Col span={12} p="xl" style={{ position: 'relative' }}>
            {isLoading ? (
              <Container
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </Container>
            ) : (
              <>
                <Title order={3} mb="xl" style={{ textAlign: 'left' }}>
                  Welcome back and happy {currentDay}, {userInfo?.firstname}!
                </Title>

                {/* New Sections */}
                <Grid>
                  {/* Announcements Placeholder */}
                  <Grid.Col span={{ base: 12 }}>
                    <Paper
                      p="lg"
                      withBorder
                      radius="md"
                      style={{
                        backgroundColor: computedColorScheme === 'dark' ? '#1a1a1a' : '#f8f9fa',
                      }}
                    >
                      <Title order={4} mb="sm">
                        Announcements
                      </Title>
                      <Timeline active={0} bulletSize={24}>
                        <Timeline.Item title="New Updates Coming Soon">
                          <Text c="dimmed" size="sm">
                            Stay tuned for important practice announcements
                          </Text>
                          <Loader type="dots" size="sm" mt={4} />
                        </Timeline.Item>
                      </Timeline>
                    </Paper>
                  </Grid.Col>

                  {/* Events Placeholder */}
                  <Grid.Col span={{ base: 12 }}>
                    <Paper
                      p="lg"
                      withBorder
                      radius="md"
                      style={{
                        backgroundColor: computedColorScheme === 'dark' ? '#1a1a1a' : '#f8f9fa',
                      }}
                    >
                      <Title order={4} mb="sm">
                        Upcoming Events
                      </Title>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                        <Paper p="md" withBorder>
                          <Text fw={500}>Annual Conference</Text>
                          <Text size="sm" c="dimmed">
                            Date: Coming Soon
                          </Text>
                          <Loader type="dots" size="sm" mt={4} />
                        </Paper>
                      </div>
                    </Paper>
                  </Grid.Col>
                </Grid>
              </>
            )}
          </Grid.Col>
        </Grid>
      </Paper>
    </Container>
  );
}
