export const isStringifiedJSON = (str: string) => {
  if (typeof str !== 'string') {
    return false;
  }

  try {
    const parsed = JSON.parse(str);
    return typeof parsed === 'object' && parsed !== null;
  } catch (e) {
    return false;
  }
};

export const parseMessage = (message: string, currentUserEmail: string) => {
  if (
    isStringifiedJSON(message) &&
    message.includes('actionBy') &&
    message.includes('addedMembers') &&
    message.includes('emailOfActionOwner')
  ) {
    const parsedSystemMessage = JSON.parse(message);
    const { addedMembers, emailOfActionOwner } = parsedSystemMessage;

    const parsedAddedMembers = JSON.parse(addedMembers);

    const addedMembersFullNames = parsedAddedMembers
      .map((member) => member.fullname)
      .join(', ');

    const ifAddedMembersEmailIsCurrentUserEmail = parsedAddedMembers.some(
      (member) => member.email === currentUserEmail,
    );

    if (
      emailOfActionOwner !== currentUserEmail &&
      ifAddedMembersEmailIsCurrentUserEmail
    ) {
      return `I added you to the group! 🎉`;
    } else {
      return `I added ${addedMembersFullNames} to the group! 🎉`;
    }
  }
  return message;
};
