import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Text,
  Group,
  Button,
  Modal,
  Flex,
  TextInput,
} from '@mantine/core';
import { RyzeAvatar } from '../AvatarUploader';
import { useNavigate } from 'react-router-dom';
import useHttpClient from '../hooks/useHttpClient';
import { notifications } from '@mantine/notifications';

interface ConnectionsListboxProps {
  isModalOpen: boolean;
  onClose: () => void;
}

export const ConnectionsListbox = ({
  isModalOpen,
  onClose,
}: ConnectionsListboxProps) => {
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [groupName, setGroupName] = useState('');
  const navigate = useNavigate();
  const httpClient = useHttpClient();

  const fetchUserConnections = async () => {
    try {
      const response = await httpClient.get(
        `/api/members/community/your-network`,
      );
      setConnections(response.data.network);
    } catch (error) {
      console.error('Error fetching accepted connections:', error);
    }
  };

  useEffect(() => {
    fetchUserConnections();
  }, [isModalOpen]);

  const handleCheckboxToggle = (id: string) => {
    setSelectedConnections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return Array.from(newSet);
    });
  };

  const handleCreateGroup = async () => {
    if (selectedConnections.length === 0) {
      notifications.show({
        title: 'Selection Required',
        message: 'Please select at least one connection',
        color: 'red',
      });
      return;
    }

    setIsSubmitting(true);
    try {
      if (selectedConnections.length === 1) {
        const { data: conversations } = await httpClient.get(
          '/api/messages/conversations',
        );
        const existingConversation = conversations.find(
          (conv) =>
            !conv.isGroup && conv.memberid === Number(selectedConnections[0]),
        );

        if (existingConversation) {
          navigate(`/wecare/messages?conversation=${existingConversation.id}`, {
            replace: true,
          });
          onClose();
        } else {
          const memberId = selectedConnections[0];
          navigate(`/wecare/messages?id=${memberId}`, { replace: true });
          onClose();
        }
      } else {
        const { data: conversations } = await httpClient.get(
          '/api/messages/conversations',
        );
        const selectedIds = [...selectedConnections]
          .map(Number)
          .sort((a, b) => a - b);

        const existingGroup = conversations.find((conv) => {
          if (!conv.isGroup) return false;
          const conversationMemberIds = conv.participantsMemberIds?.sort(
            (a, b) => a - b,
          );
          return (
            JSON.stringify(conversationMemberIds) ===
            JSON.stringify(selectedIds)
          );
        });

        if (existingGroup) {
          navigate(`/wecare/messages?conversation=${existingGroup.id}`, {
            replace: true,
          });
          onClose();
        } else {
          const { data } = await httpClient.post('/api/messages/', {
            text: 'Group chat created',
            recipientids: selectedIds,
            name: groupName || `Group (${selectedIds.length + 1})`,
            isGroup: true,
          });

          if (data.conversationId) {
            navigate(`/wecare/messages?conversation=${data.conversationId}`, {
              replace: true,
            });
            onClose();
          }
        }
      }
    } catch (error) {
      notifications.show({
        title: 'Error creating conversation',
        message: error.message,
        color: 'red',
      });
    } finally {
      setIsSubmitting(false);
      setSelectedConnections([]);
    }
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={onClose}
      title={<Text fw="bold">Add People to the Group Chat</Text>}
      size="sm"
      centered
    >
      <Modal.Body>
        <Flex direction={'column'}>
          <TextInput
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            mb="sm"
          />
          <Flex
            direction="column"
            style={{
              maxHeight: '300px',
              overflowY: 'auto',
              padding: '0 4px',
            }}
          >
            {connections.map((connection) => {
              return (
                <Group key={connection.id} mb="sm">
                  <Checkbox
                    checked={selectedConnections.includes(connection.id)}
                    onChange={() => handleCheckboxToggle(connection.id)}
                  />
                  <RyzeAvatar
                    key={connection.id}
                    memberId={connection.id}
                    style={{ marginRight: '10px' }}
                  />
                  <Text>
                    {connection.firstname} {connection.lastname}
                  </Text>
                </Group>
              );
            })}
          </Flex>
        </Flex>
      </Modal.Body>
      <Group justify="space-between">
        <Button onClick={onClose} color="red" style={{ marginTop: '10px' }}>
          Cancel
        </Button>
        <Button
          onClick={handleCreateGroup}
          style={{ marginTop: '10px' }}
          loading={isSubmitting}
          disabled={selectedConnections.length === 0}
        >
          {selectedConnections.length === 1 ? 'Start Chat' : 'Create Group'}
        </Button>
      </Group>
    </Modal>
  );
};

export default ConnectionsListbox;
