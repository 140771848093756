import React, { useEffect, useState } from 'react';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Title,
  Grid,
  Card,
  Text,
  Divider,
  Anchor,
  Center,
  Flex,
} from '@mantine/core';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import CheckoutForm from './CheckoutForm';
import { OrganizationMember } from '../../types/subscribermember.types';
import { useAuth } from '../hooks/useAuth';
import { useStepper } from './RegistrationSteppers';
import MonthlyPremium from './MonthPremium';

const stripePromise = loadStripe(
  'pk_test_51PdGuuHon8D4YU7NG1z8ZO4l3r67GXdiz9qd0BvJ44mKe9K2ZVJeubLskFrD43HwsydlsCMUZHzv6WO9NBFr9Q7500aNK8nczN',
);

interface PlanSelectionProps {
  isMember?: boolean;
  onFieldsCompleteChange?: (isComplete: boolean) => void;
}

const PaymentInformationTab: React.FC<PlanSelectionProps> = ({
  isMember,
  onFieldsCompleteChange,
}) => {
  const { organizationId } = useAuth();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [resetPaymentMethod, setResetPaymentMethod] = useState(false);
  const [paymentCustomerId, setPaymentCustomerId] = useState<string>();
  const httpClient = useHttpClient();
  const [paymentInfo, setPaymentInfo] = useState(null);
  const { markStepAsDone } = useStepper();

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await httpClient.get(`/api/organization`);
        return response.data;
      } catch (e) {
        console.error('Error while fetching Organization');
      }
    };

    const checkSubscription = async () => {
      try {
        const organization = await fetchOrganization();
        if (organization?.stripecustomerid) {
          setPaymentCustomerId(organization.stripecustomerid);
          const response = await httpClient.get(
            '/api/stripe/check-subscription',
            {
              params: { organizationId },
            },
          );
          const { hasSubscription } = response.data;
          if (hasSubscription) {
            const subscriptionData = response.data.subscription;
            setSubscriptionExists(true);
          } else {
            await handleStartPaymentSetup();
            setSubscriptionExists(false);
          }
        } else {
          // Create Stripe customer ID if it doesn't exist
          const createCustomerResponse = await httpClient.post(
            '/api/stripe/create-stripe-customer',
          );

          const { customerId } = createCustomerResponse.data;
          setPaymentCustomerId(customerId);
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    };

    const fetchMembers = async () => {
      try {
        const response = await httpClient.get(
          `/api/subscriber/organization/${organizationId}`,
        );
        const fetchedMembers: OrganizationMember[] = response.data;

        const premiumNumbers = await httpClient.get(`/api/premium/`);
        if (
          premiumNumbers.data.length > 0 &&
          JSON.stringify(premiumNumbers.data[0]) !== JSON.stringify(paymentInfo)
        ) {
          setPaymentInfo(premiumNumbers.data[0]);
        }
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    if (organizationId) {
      checkSubscription();
      fetchMembers();
    }
  }, [organizationId]);

  useEffect(() => {
    if (subscriptionExists || paymentInfo) {
      markStepAsDone('payment-information');
      onFieldsCompleteChange(true);
    }
  }, [subscriptionExists, paymentInfo]);

  const handleStartPaymentSetup = async () => {
    try {
      const response = await httpClient.post(
        '/api/stripe/create-setup-intent',
        {
          email: 'test@test.com',
          username: 'stripetest',
        },
      );
      const data = response.data;
      setClientSecret(data.clientSecret);
      setResetPaymentMethod(true);
    } catch (error) {
      console.error('Error creating setup intent:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to initialize payment setup',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Container>
      <Title order={1} style={{ textAlign: 'center' }}>
        Payment Information
      </Title>
      <br />

      <Paper withBorder shadow="xs" p="sm">
        <>
          <Grid justify="space-between" align="center" mb="md">
            <Grid.Col span={11}>
              <Title order={3}>Premium and Payment Setup</Title>
            </Grid.Col>
          </Grid>

          {/* Plan Summary */}
          {paymentInfo && (
            <Card shadow="sm" padding="lg" mb="md">
              <Title order={4}>
                {paymentInfo.planName} - Plan ID: {paymentInfo.planId}
              </Title>
              <Divider my="sm" />
              <Text>Total Members: {paymentInfo.totalMembers}</Text>
              <Text>Total Families: {paymentInfo.totalFamilies}</Text>
              <Text>Group Area Factor: {paymentInfo.groupAreaFactor}</Text>
              <Text>
                State average Group Area Factor:{' '}
                {paymentInfo.groupAreaFactorForStateAverage}
              </Text>
              <Text>
                Total Age-Gender Factor: {paymentInfo.totalAgeGenderFactor}
              </Text>
              <Text>Total Premium: ${paymentInfo.totalPremium}</Text>
              <Text>
                Total State average premium: $
                {paymentInfo.totalPremiumForStateAverage}
              </Text>
              <Text>
                Average Monthly Premium (PMPM): $
                {paymentInfo.averageMonthlyPremiumPMPM}
              </Text>
            </Card>
          )}

          {/* Payment Information */}
          {subscriptionExists ? (
            <Card shadow="sm" padding="lg">
              {/* <Text
                component="a"
                href="#"
                color="blue"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginTop: '1em',
                }}
                onClick={handleStartPaymentSetup}
              >
                Reset Payment Method
              </Text> */}
            </Card>
          ) : clientSecret ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <MonthlyPremium isMember={isMember} />
              <CheckoutForm
                clientSecret={clientSecret}
                paymentCustomerId={paymentCustomerId}
              />
            </Elements>
          ) : (
            <Flex justify="flex-end">
              <Button onClick={handleStartPaymentSetup}>
                Start Payment Setup
              </Button>
            </Flex>
          )}
        </>
      </Paper>
    </Container>
  );
};

export default PaymentInformationTab;
