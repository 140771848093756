import { useState, useEffect, useMemo } from 'react';
import {
  UnstyledButton,
  Tooltip,
  Text,
  rem,
  Avatar,
  Group,
  Flex,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import classes from './DoubleNavbar.module.css';
import { useAuth } from './AuthProvider';
import { User } from 'tabler-icons-react';
import { RyzeAvatar } from './AvatarUploader';

export function DoubleNavbar({ location, mainMenu = [], subMenu }) {
  // main menu
  const f = mainMenu.filter((menu) => menu.path == location.pathname)[0];
  const [active, setActive] = useState(f?.label || mainMenu?.[0]?.label);
  const navigate = useNavigate();
  const { logoutAuth } = useAuth();
  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const type = privileges[0].type
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  useEffect(() => {
    const path = mainMenu?.filter((l) => l.path === location?.pathname)[0]
      ?.label;
    setActive(path);
  }, [location]);

  const hasInsurancecPhysicianPrivilege = privileges.some(
    (p) => p.type === 'insurance_physician',
  );

  const mainLinks = mainMenu.map((link) => {
    if (link.requiredPrivileges?.length) {
      const privileges_ = privileges.map((p) => p.type);
      const hasAll = link.requiredPrivileges.every((item) =>
        privileges_.includes(item),
      );

      if (!hasAll) {
        return <></>;
      }
    }
    return link.label === 'Signup for Ryze' &&
      hasInsurancecPhysicianPrivilege ? (
      <></>
    ) : (
      <Tooltip
        label={link.label}
        position="right"
        withArrow
        transitionProps={{ duration: 0 }}
        key={link.label}
      >
        <UnstyledButton
          onClick={() => {
            if (link.path === '/logout') {
              logoutAuth();
            } else {
              if (link.external) {
                window.open(link.path, '_blank', 'noopener,noreferrer');
              } else {
                navigate(link.path || '/');
              }
            }
          }}
          className={classes.mainLink}
          data-active={link.label === active || undefined}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <link.icon
            style={{ width: rem(30), height: rem(30), marginRight: rem(12) }}
            stroke={1.5}
          />
          <Text size="sm">{link.label}</Text>
        </UnstyledButton>
      </Tooltip>
    );
  });

  // sub menu
  const { hidden, menu, allowSwitchingBetweenPages = true } = subMenu;
  const hash = location.hash.substring(1);
  const [activeLink, setActiveLink] = useState(hash || menu?.[0]?.key);
  useEffect(() => {
    setActiveLink(hash);
  }, [hash, location.hash]);

  const links =
    !hidden &&
    menu?.map((link) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={
          allowSwitchingBetweenPages ? classes.link : classes.linkDisabled
        }
        data-active={
          activeLink === link.key ||
          (!activeLink && link.key === menu?.[0]?.key) ||
          undefined
        }
        href="#"
        onClick={(event) => {
          event.preventDefault();
          if (allowSwitchingBetweenPages) {
            setActiveLink(link.key);
            window.location.hash = link.key;
          }
        }}
        key={link.key}
      >
        {link.value}
      </a>
    ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={links?.length ? classes.aside : classes.asideClosed}>
          {mainLinks}
        </div>
        {menu && <div className={classes.main}>{links}</div>}
      </div>
      <div className="classes.userInfo">
        {mainMenu?.length && (
          <Flex className={classes.userInfo} align="center">
            <RyzeAvatar radius="xl" mr={5} memberId={privileges[0].serviceid}>
              <User size={24} />
            </RyzeAvatar>
            <div style={{ width: 'calc(100% - 50px)' }}>
              <Text size="sm">{type}</Text>
              <Text size="xs" color="dimmed" className={classes.username}>
                {username}
              </Text>
            </div>
          </Flex>
        )}
      </div>
    </nav>
  );
}
