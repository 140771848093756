import {
  MultiSelect,
  TextInput,
  Checkbox,
  Stack,
  Title,
  Text,
  Box,
} from '@mantine/core';
import { useState } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

interface PracticeLocationAffliationFormProps {
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  practiceIndex: number;
  practiceLocationIndex: number;
  loading: boolean;
}

const PracticeLocationAccessibilityForm: React.FC<
  PracticeLocationAffliationFormProps
> = ({ control, register, practiceIndex, practiceLocationIndex, loading }) => {
  const locationAccessibilityOptions = [
    { value: 'exam-table', label: 'Exam table/scale/chair' },
    { value: 'gurneys', label: 'Gurneys & stretchers' },
    { value: 'interior', label: 'Interior building' },
    { value: 'parking', label: 'Parking' },
    { value: 'lifts', label: 'Portable lifts' },
    { value: 'radiologic', label: 'Radiologic equipment' },
    { value: 'restroom', label: 'Restroom' },
    { value: 'signage', label: 'Signage & documents' },
    { value: 'wheelchair', label: 'Wheelchair access to exam room' },
    { value: 'exterior', label: 'Exterior building' },
  ];

  const intellectualDisabilityOptions = [
    {
      value: 'cognitive',
      label:
        'Accommodations for people with intellectual/cognitive disabilities (e.g., on-site staff to explain instructions)',
    },
    {
      value: 'tty',
      label:
        'Teletypewriter (TTY) or Telecommunications Device for the Deaf (TDD)',
    },
    { value: 'asl', label: 'American Sign Language' },
    {
      value: 'impairment',
      label: 'Mental/Physical Impairment Services Other disability services',
    },
  ];

  const transportationOptions = [
    { value: 'bus', label: 'Bus' },
    { value: 'subway', label: 'Subway' },
    { value: 'train', label: 'Regional Train' },
  ];

  return (
    <Box p="md" w="100%">
      <Stack>
        <Box>
          <Controller
            name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.locationAccess`}
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="How this location is accessible?"
                data={locationAccessibilityOptions}
                placeholder="Select accessibility options"
                searchable
                clearable
                maxDropdownHeight={200}
                styles={{ dropdown: { maxWidth: '100%' } }}
                disabled={loading}
              />
            )}
          />

          <TextInput
            placeholder="Please specify other accessibility options"
            label="Others (Please specify)"
            mt="sm"
            style={{ maxWidth: '50%' }}
            disabled={loading}
            {...register(
              `practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.locationOther`,
            )}
          />
        </Box>

        <Box>
          <Controller
            name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.intellectualAccess`}
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="What accommodations are available for people with intellectual disabilities?"
                data={intellectualDisabilityOptions}
                placeholder="Select accommodation options"
                searchable
                clearable
                maxDropdownHeight={200}
                styles={{ dropdown: { maxWidth: '100%' } }}
                disabled={loading}
              />
            )}
          />

          <TextInput
            placeholder="Please specify other accommodation options"
            label="Others (Please specify)"
            mt="sm"
            style={{ maxWidth: '50%' }}
            disabled={loading}
            {...register(
              `practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.intellectualOther`,
            )}
          />
        </Box>

        <Box>
          <Text mb={4}></Text>
          <Controller
            name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.transportationAccess`}
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="What transportation options are available to this location?"
                data={transportationOptions}
                placeholder="Select transportation options"
                searchable
                clearable
                maxDropdownHeight={200}
                styles={{ dropdown: { maxWidth: '100%' } }}
                disabled={loading}
              />
            )}
          />

          <TextInput
            placeholder="Please specify other transportation options"
            label="Others (Please specify)"
            mt="sm"
            style={{ maxWidth: '50%' }}
            disabled={loading}
            {...register(
              `practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.transportationOther`,
            )}
          />
        </Box>

        <Box>
          <Stack>
            <Controller
              name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.childcare`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  label="This location provides child care services"
                  disabled={loading}
                />
              )}
            />
            <Controller
              name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.safety`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  label="This location meets all state and local fire, safety and sanitation requirements"
                  disabled={loading}
                />
              )}
            />
            <Controller
              name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.training`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  label="Staff at this location receive ADA compliance training"
                  disabled={loading}
                />
              )}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default PracticeLocationAccessibilityForm;
