import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Select,
  Text,
} from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { STATES_ARRAY } from '../../types/members.types';
import useHttpClient from '../hooks/useHttpClient';
import { notifications } from '@mantine/notifications';
import { useFormDirtyAlert } from '../../helpers/useFormDirtyAlert';

interface NewsletterFormProps {
  onFormSubmit?: (data: any) => void;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({ onFormSubmit }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm();
  useFormDirtyAlert(reset, isDirty, dirtyFields);
  const httpClient = useHttpClient();

  // Initial priorities list
  const initialPriorities = [
    { id: '1', label: 'Affordable Costs' },
    {
      id: '2',
      label: 'Ease of use and navigation (doctor and hospital choice)',
    },
    { id: '3', label: 'Billing clarity and transparency' },
  ];

  const [priorities, setPriorities] = useState(initialPriorities);

  const onDragEnd = (result) => {
    if (!result.destination) return; // If the item is dropped outside the list, do nothing
    const updatedPriorities = Array.from(priorities);
    const [reorderedItem] = updatedPriorities.splice(result.source.index, 1);
    updatedPriorities.splice(result.destination.index, 0, reorderedItem);
    setPriorities(updatedPriorities);
  };

  const onSubmit = async (data: any) => {
    // If "Other" is selected for referralSource, replace the value with the custom input
    if (data.referralsource === 'Other') {
      data.referralsource = data.referralsourcecustom;
    }
    delete data.referralsourcecustom; // Clean up the custom input field from data

    const submissionData = {
      ...data,
      priorities: priorities.map((priority) => priority.label), // Submit priorities in the ranked order
    };

    try {
      const response = await httpClient.post('/api/newsletter', submissionData);
      if (onFormSubmit) {
        onFormSubmit(response.data);
      }
      notifications.show({
        title: 'Success',
        message: 'Successfully signed up for the newsletter!',
        color: 'green',
        position: 'bottom-center',
      });
      reset(); // Reset the form after successful submission
    } catch (error) {
      console.error('Error submitting newsletter form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit the newsletter form',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Newsletter Signup</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                required
                type="email"
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="Practice Name" {...register('practicename')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                  />
                )}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Referral Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <Controller
                name="referralsource"
                control={control}
                rules={{ required: 'Please select how you heard about us' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="How did you hear about us?"
                    placeholder="Select an option"
                    data={[
                      { value: 'RPPA', label: 'RPPA' },
                      { value: 'Wecare Event', label: 'Wecare Event' },
                      { value: 'MSSNY', label: 'MSSNY' },
                      { value: 'ACP', label: 'ACP' },
                      { value: 'PIMD', label: 'PIMD' },
                      { value: 'Other', label: 'Other (Please specify)' },
                    ]}
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>

            {/* Conditionally render the text input when "Other" is selected */}
            {watch('referralsource') === 'Other' && (
              <Grid.Col span={6}>
                <TextInput
                  label="Please specify"
                  placeholder="Enter the source"
                  {...register('referralsourcecustom', { required: true })}
                  required
                />
              </Grid.Col>
            )}
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Rank Your Priorities</Title>
          <Text mt="xs" size="sm">
            Drag and drop to rank your health insurance priorities from top to
            bottom.
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="priorities">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {priorities.map((priority, index) => (
                        <Draggable
                          key={priority.id}
                          draggableId={priority.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                padding: '8px',
                                margin: '4px 0',
                                backgroundColor: '#f1f1f1',
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'grab',
                                userSelect: 'none',
                                ...provided.draggableProps.style,
                              }}
                            >
                              <span>{priority.label}</span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid.Col>
          </Grid>
        </Paper>

        <Button type="submit" mt="md">
          Sign Up
        </Button>
      </form>
    </Container>
  );
};

export default NewsletterForm;
