import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Input,
  Grid,
  Title,
  Paper,
  Text,
  PasswordInput,
  Textarea,
} from '@mantine/core';
import { STATES_ARRAY } from '../../types/members.types';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useFormDirtyAlert } from '../../helpers/useFormDirtyAlert';

interface ProviderPracticeFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const ProviderPracticeForm: React.FC<ProviderPracticeFormProps> = ({
  initialData,
  onFormSubmit,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password'); // Watch password for confirmation validation

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      setIsSubmitting(false);
      return;
    }

    // If "Other" is selected for referralSource, replace the value with the custom input
    if (data.referralsource === 'Other') {
      data.referralsource = data.referralsourcecustom;
    }
    delete data.referralsourcecustom; // Clean up the custom input field from data

    // Cast email to lowercase and remove password confirmation before submission
    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
    };
    delete submissionData.passwordConfirmation; // Remove password confirmation field from submission

    try {
      // Submit the form data to the API
      const response = await httpClient.post(
        '/api/provider/practice/register',
        submissionData,
      );

      if (onFormSubmit) {
        onFormSubmit(response.data);
      }

      notifications.show({
        title: 'Success',
        message: 'Practice registered successfully',
        color: 'green',
        position: 'bottom-center',
      });

      // Pass addUserResult to the confirmation page via navigate
      navigate('/provider/register/confirmation', {
        state: {
          addUserResult: response.data.addUserResult, // Pass addUserResult from the response
          practiceExists: response.data.practiceExists, // Pass if practice already exists
        },
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to register practice',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Business Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Practice Name"
                required
                {...register('name', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Practice Website"
                {...register('practicewebsite')}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Tax Identification Number"
                maxLength={9}
                required
                {...register('tin', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Type 2 Organization NPI"
                maxLength={10}
                required
                {...register('npi', { required: true })}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Address Information</Title>
          <Text size="sm">
            Start typing your address in Street 1 and select your address from
            the dropdown.
          </Text>
          <TextInput
            label={'\u0405treet 1'}
            required
            {...register('street1')}
          />
          <TextInput label={'\u0405treet 2'} {...register('street2')} />
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="City" required {...register('city')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="state"
                control={control}
                rules={{ required: 'State is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="ZIP"
                maxLength={5}
                required
                {...register('zip')}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    data={[{ value: 'US', label: 'United States' }]}
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <TextInput label="County" required {...register('county')} />
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Administrator Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Admin First Name"
                required
                {...register('adminfirstname')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Admin Last Name"
                required
                {...register('adminlastname')}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Admin Email"
                required
                {...register('adminemail')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="adminphone"
                control={control}
                render={({ field }) => (
                  <Input.Wrapper label="Admin Phone">
                    <Input
                      component={IMaskInput}
                      {...field}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                    />
                  </Input.Wrapper>
                )}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Login Information</Title>
          <Text mt="xs" size="sm">
            This information will be used to create your account. Please choose
            a password of at least "Good" strength.
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                required
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <PasswordInput
                label="Password"
                required
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                maxLength={50}
                {...register('password', { required: true })}
              />
              <PasswordStrengthBar
                password={password}
                onChangeScore={(score) => setPasswordStrengthScore(score)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                label="Confirm Password"
                required
                visible={showPassword}
                onVisibilityChange={setShowPassword}
                {...register('passwordConfirmation', {
                  required: 'Password confirmation is required',
                  validate: (value) =>
                    value === password || 'Passwords do not match',
                })}
                error={errors.passwordConfirmation?.message?.toString()}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Referral Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <Controller
                name="referralsource"
                control={control}
                rules={{ required: 'Please select how you heard about us' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="How did you hear about us?"
                    placeholder="Select an option"
                    data={[
                      { value: 'RPPA', label: 'RPPA' },
                      { value: 'Wecare Event', label: 'Wecare Event' },
                      { value: 'MSSNY', label: 'MSSNY' },
                      { value: 'ACP', label: 'ACP' },
                      { value: 'PIMD', label: 'PIMD' },
                      { value: 'Other', label: 'Other (Please specify)' },
                    ]}
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>

            {/* Conditionally render the text input when "Other" is selected */}
            {watch('referralsource') === 'Other' && (
              <Grid.Col span={6}>
                <TextInput
                  label="Please specify"
                  placeholder="Enter the source"
                  {...register('referralsourcecustom', { required: true })}
                  required
                />
              </Grid.Col>
            )}
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Questions or Comments</Title>
          <Text mt="xs" size="sm">
            If you have any questions, comments, concerns about your
            registration or Ryze Health, or simply want to share your thoughts,
            feel free to leave us a message below.
          </Text>
          <Textarea
            placeholder="Please write any questions or comments you have here..."
            minRows={4}
            {...register('message')}
          />
        </Paper>

        <Button
          type="submit"
          mt="md"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Sign Up
        </Button>
      </form>
    </Container>
  );
};

export default ProviderPracticeForm;
