import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Grid,
  Paper,
  Group,
  Collapse,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import CustomDateInput from './CustomDateInput';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';

interface FormProps {
  memberId: number;
}

const CertificationForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, dirtyFields },
  } = useForm({});

  // TODO: broken on certifcation form
  // useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'certifications',
  });

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<any>();

  const httpClient = useHttpClient();

  const fetchCertifications = async () => {
    console.log('Fetching certifications for memberId:', memberId); // Debug statement
    try {
      const certificationsResponse = await httpClient.get(
        `/api/certifications/${memberId}`,
      );
      console.log(
        'Certifications response status:',
        certificationsResponse.status,
      ); // Debug statement

      const fetchedCertifications = certificationsResponse.data;
      console.log('Fetched certifications:', fetchedCertifications); // Debug statement

      const sortedCertifications = fetchedCertifications.sort(
        (a: any, b: any) => {
          const expirationDateComparison =
            new Date(a.expirationdate).getTime() -
            new Date(b.expirationdate).getTime();
          if (expirationDateComparison !== 0) return expirationDateComparison;
          const issueDateComparison =
            new Date(a.issuedate).getTime() - new Date(b.issuedate).getTime();
          if (issueDateComparison !== 0) return issueDateComparison;
          const certificateNumberComparison = a.certificatenumber.localeCompare(
            b.certificatenumber,
          );
          if (certificateNumberComparison !== 0)
            return certificateNumberComparison;
          return a.id - b.id;
        },
      );

      const certificationsextraResponse = await httpClient.get(
        `/api/certificationsextra/${memberId}`,
      );
      console.log(
        'Certifications extra response status:',
        certificationsextraResponse.status,
      ); // Debug statement
      let fetchedExtra = { culturalcompetencytraining: '' };

      if (certificationsextraResponse.status === 200) {
        fetchedExtra = certificationsextraResponse.data;
      }
      console.log('Fetched certifications extra:', fetchedExtra); // Debug statement

      reset({
        certifications: sortedCertifications,
        certificationsextra: fetchedExtra,
      });
      setInitialData({
        certifications: sortedCertifications,
        certificationsextra: fetchedExtra,
      });
      setCollapsedIndices(sortedCertifications.map(() => false));
    } catch (error) {
      console.error('Error fetching certifications:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch certifications',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchCertifications();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('Submit form values:', data); // Debug statement

    const certificationsWithMemberId = data.certifications.map(
      (certification: any) => ({
        ...certification,
        memberid: memberId, // Ensure memberid is included
      }),
    );

    const createCertification = async (certification: any) => {
      console.log('Creating certification:', certification); // Debug statement
      const response = await httpClient.post(
        '/api/certifications',
        certification,
      );
      return response.data;
    };

    const updateCertification = async (certification: any) => {
      console.log('Updating certification:', certification); // Debug statement
      const response = await httpClient.put(
        `/api/certifications/${certification.id}`,
        certification,
      );
      return response.data;
    };

    const deleteCertification = async (id: number) => {
      console.log('Deleting certification ID:', id); // Debug statement
      const response = await httpClient.delete(`/api/certifications/${id}`);
      return response.data;
    };

    const createCertificationExtra = async (extraData: any) => {
      const extraDataWithMemberId = { ...extraData, memberid: memberId }; // Ensure memberid is included
      console.log('Creating certification extra:', extraDataWithMemberId); // Debug statement
      const response = await httpClient.post(
        '/api/certificationsextra',
        extraDataWithMemberId,
      );
      console.log('Create certification extra response:', response); // Debug statement
      return response.data;
    };

    const updateCertificationExtra = async (extraData: any) => {
      const extraDataWithMemberId = { ...extraData, memberid: memberId }; // Ensure memberid is included
      console.log('Updating certification extra:', extraDataWithMemberId); // Debug statement
      const response = await httpClient.put(
        `/api/certificationsextra/${memberId}`,
        extraDataWithMemberId,
      );
      console.log('Update certification extra response:', response); // Debug statement
      return response.data;
    };

    const deleteCertificationExtra = async () => {
      console.log('Deleting certification extra for memberId:', memberId); // Debug statement
      const response = await httpClient.delete(
        `/api/certificationsextra/${memberId}`,
      );
      console.log('Delete certification extra response:', response); // Debug statement
      return response.data;
    };
    try {
      let extraTask = null;
      if (data.certificationsextra.culturalcompetencytraining) {
        extraTask =
          initialData?.certificationsextra &&
          initialData.certificationsextra.culturalcompetencytraining
            ? updateCertificationExtra(data.certificationsextra)
            : createCertificationExtra(data.certificationsextra);
      } else if (
        initialData?.certificationsextra &&
        initialData.certificationsextra.culturalcompetencytraining
      ) {
        extraTask = deleteCertificationExtra();
      }

      const createTasks = certificationsWithMemberId
        .filter((c: any) => !c.id || c.id === 0)
        .map((c: any) => createCertification(c));

      const updateTasks = certificationsWithMemberId
        .filter((c: any) => c.id && c.id > 0)
        .map((c: any) => updateCertification(c));

      const deleteTasks = initialData?.certifications
        .filter(
          (initialCert: any) =>
            !data.certifications.some(
              (cert: any) => cert.id === initialCert.id,
            ),
        )
        .map((cert: any) => deleteCertification(cert.id));

      await Promise.all([...createTasks, ...updateTasks, ...deleteTasks]);

      notifications.show({
        title: 'Success',
        message: 'Certifications updated successfully',
        color: 'green',
        position: 'top-center',
      });
    } catch (error) {
      console.error('Error submitting certification form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit certification',
        color: 'red',
        position: 'top-center',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Certification {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name={`certifications.${index}.certificationname`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Certification Name"
                        data={[
                          {
                            value: 'Qualified Autism Service Provider (QASP)',
                            label: 'Qualified Autism Service Provider (QASP)',
                          },
                          {
                            value: 'Cardio Pulmonary Resuscitation (CPR)',
                            label: 'Cardio Pulmonary Resuscitation (CPR)',
                          },
                          {
                            value: 'Basic Life Support (BLS)',
                            label: 'Basic Life Support (BLS)',
                          },
                          {
                            value: 'Advanced Cardiac Life Support (ACLS)',
                            label: 'Advanced Cardiac Life Support (ACLS)',
                          },
                          {
                            value: 'Advanced Life Support in OB (ALSO)',
                            label: 'Advanced Life Support in OB (ALSO)',
                          },
                          {
                            value: 'Health Care Provider (CoreC)',
                            label: 'Health Care Provider (CoreC)',
                          },
                          {
                            value: 'Advanced Trauma Life Support (ATLS)',
                            label: 'Advanced Trauma Life Support (ATLS)',
                          },
                          {
                            value: 'Neonatal Advanced Life Support (NALS)',
                            label: 'Neonatal Advanced Life Support (NALS)',
                          },
                          {
                            value: 'Neonatal Resuscitation Program (NRP)',
                            label: 'Neonatal Resuscitation Program (NRP)',
                          },
                          {
                            value: 'Pediatric Advanced Life Support (PALS)',
                            label: 'Pediatric Advanced Life Support (PALS)',
                          },
                          {
                            value: 'Anesthesia Permit',
                            label: 'Anesthesia Permit',
                          },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Certificate Number"
                    {...register(`certifications.${index}.certificatenumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`certifications.${index}.issuedate`}
                    label="Issue Date"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`certifications.${index}.expirationdate`}
                    label="Expiration Date"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`certifications.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                certificationname: '',
                certificatenumber: '',
                issuedate: null,
                expirationdate: null,
                state: '',
              })
            }
          >
            Add Certification
          </Button>
        </Group>
        <Controller
          name="certificationsextra.culturalcompetencytraining"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Have you completed cultural competency training?"
              data={[
                { value: 'Y', label: 'Yes' },
                { value: 'N', label: 'No' },
              ]}
              clearable
            />
          )}
        />
        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default CertificationForm;
