import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Grid,
  Paper,
  Title,
  Text,
  PasswordInput,
  Textarea,
  Checkbox,
  Loader,
  Flex,
} from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import PasswordStrengthBar from 'react-password-strength-bar';
import { STATES_ARRAY } from '../../types/members.types';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';
import {
  useOptionalWecareMemberContext,
  OptionalWecareMemberProvider,
} from '../OptionalWecareMemberProvider';
import RyzeLogo from '../RyzeLogo';
import { useFormDirtyAlert } from '../../helpers/useFormDirtyAlert';

interface PhysicianFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

export const PhysicianForm: React.FC<PhysicianFormProps> = ({
  initialData,
  onFormSubmit,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { memberId } = useOptionalWecareMemberContext();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [isMemberLoaded, setIsMemberLoaded] = useState(false); // Track if member data is loaded
  const [wecareExistingUser, setWecareExistingUser] = useState(false); // Track whether to disable fields
  const [professionalIds, setProfessionalIds] = useState([]);
  const fetchMemberDataRef = useRef(false); // Track if fetchMemberData has already run
  const [loading, setLoading] = useState(false);

  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password');

  const initialPriorities = [
    { id: '1', label: 'Affordable Costs' },
    {
      id: '2',
      label: 'Ease of use and navigation (doctor and hospital choice)',
    },
    { id: '3', label: 'Billing clarity and transparency' },
  ];

  const [priorities, setPriorities] = useState(initialPriorities);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedPriorities = Array.from(priorities);
    const [reorderedItem] = updatedPriorities.splice(result.source.index, 1);
    updatedPriorities.splice(result.destination.index, 0, reorderedItem);
    setPriorities(updatedPriorities);
  };

  const onSubmit = async (data: any) => {
    if (!wecareExistingUser && passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }

    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
      type: 'independent',
      subscriberrelationship: 'Primary',
      priorities: priorities.map((priority) => priority.label),
    };

    delete submissionData.passwordConfirmation;

    try {
      setLoading(true);
      const response = await httpClient.post(
        '/api/subscriber/verify',
        submissionData,
      );
      const addUserResult = response.data.addUserResult;

      if (onFormSubmit) {
        onFormSubmit(response.data);
      }

      navigate('/insurance/register/confirmation', {
        state: { addUserResult },
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit physician form',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchMemberData = async () => {
    try {
      setLoading(true);
      if (memberId && memberId > 0) {
        // Avoid fetching again if it's already fetched once
        if (fetchMemberDataRef.current) return;
        fetchMemberDataRef.current = true;

        // Fetch member data
        const memberResponse = await httpClient.get(`/api/members/${memberId}`);
        const memberData = memberResponse.data;

        setValue('firstname', memberData.firstname);
        setValue('lastname', memberData.lastname);
        setValue('npi', memberData.npi);
        setValue('email', memberData.email);

        // Fetch professional IDs
        const professionalIdsResponse = await httpClient.get(
          `/api/professionalids/license/${memberId}`,
        );
        const professionalIds = professionalIdsResponse.data;

        if (professionalIds.length > 0) {
          setProfessionalIds(professionalIds);

          // Set initial values only if they haven't been manually set
          const firstProfessionalId = professionalIds[0];
          setValue('licensenumber', firstProfessionalId.idnumber);
          setValue('licensestate', firstProfessionalId.state);
        }

        setWecareExistingUser(true);
        setIsMemberLoaded(true);
      }
    } catch (error) {
      console.log('No member data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLicenseChange = (value) => {
    const selectedLicense = professionalIds.find((id) => id.idnumber === value);
    if (selectedLicense) {
      setValue('licensestate', selectedLicense.state);
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchMemberData();
    } else {
      // Reset fields and enable them if no memberId is present
      reset();
      setWecareExistingUser(false);
      setIsMemberLoaded(false);
    }
  }, [memberId]);

  return (
    <Container>
      <Title order={1} style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
        Help Us Verify Your Eligibility.
      </Title>
      <Title order={4} style={{ textAlign: 'center', marginBottom: '2rem' }}>
        To create your account, we need a few details to confirm you're a
        physician—this helps us ensure a secure and professional community.
      </Title>
      {isMemberLoaded || !memberId ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          {memberId && (
            <Text mt="xs" size="sm" color="red">
              Your information was prepopulated from your Wecare account where
              available. You do not need to create another account
            </Text>
          )}
          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3} mb="sm">
              Personal Information
            </Title>
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="First Name"
                  required
                  disabled={wecareExistingUser || loading}
                  {...register('firstname', { required: true })}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Last Name"
                  required
                  disabled={wecareExistingUser || loading}
                  {...register('lastname', { required: true })}
                />
              </Grid.Col>
            </Grid>
            {wecareExistingUser ? (
              // Grid component for existing WeCare users with enabled, non-clearable selects
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name="licensenumber"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Medical License Number"
                        placeholder="Select a license"
                        data={professionalIds.map((id) => ({
                          value: id.idnumber,
                          label: id.idnumber,
                        }))}
                        onChange={(value) => {
                          field.onChange(value);
                          handleLicenseChange(value);
                        }}
                        required
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="licensestate"
                    control={control}
                    rules={{ required: 'State is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Medical License State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable={false}
                        disabled
                        searchable
                        required
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            ) : (
              // Original Grid component for new users with clearable, disabled selects
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Medical License Number"
                    required
                    {...register('licensenumber', { required: true })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="licensestate"
                    control={control}
                    rules={{ required: 'State is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Medical License State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        searchable
                        required
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            )}
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="NPI"
                  required
                  maxLength={10}
                  disabled={wecareExistingUser || loading}
                  {...register('npi', { required: true })}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                  }}
                />
              </Grid.Col>
            </Grid>
          </Paper>

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3} mb="sm">
              Login Information
            </Title>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Email"
                  required
                  disabled={wecareExistingUser || loading}
                  {...register('email', { required: true })}
                />
              </Grid.Col>
            </Grid>
            {/* Skip password section if user exists */}
            {!wecareExistingUser && (
              <Grid>
                <Grid.Col span={6}>
                  <PasswordInput
                    label="Password"
                    required
                    disabled={loading}
                    visible={showPassword}
                    onVisibilityChange={setShowPassword}
                    maxLength={50}
                    {...register('password', { required: true })}
                  />
                  <PasswordStrengthBar
                    password={password}
                    onChangeScore={(score) => setPasswordStrengthScore(score)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <PasswordInput
                    label="Confirm Password"
                    required
                    disabled={loading}
                    visible={showPassword}
                    onVisibilityChange={setShowPassword}
                    {...register('passwordConfirmation', {
                      required: 'Password confirmation is required',
                      validate: (value) =>
                        value === password || 'Passwords do not match',
                    })}
                    error={errors.passwordConfirmation?.message?.toString()}
                  />
                </Grid.Col>
              </Grid>
            )}
          </Paper>

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3} mb="sm">
              Additional Information
            </Title>
            <Grid>
              <Grid.Col span={6}>
                <Controller
                  name="referralsource"
                  control={control}
                  rules={{ required: 'Please select how you heard about us' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="How did you hear about us?"
                      placeholder="Select an option"
                      data={[
                        { value: 'RPPA', label: 'RPPA' },
                        { value: 'Wecare Event', label: 'Wecare Event' },
                        { value: 'MSSNY', label: 'MSSNY' },
                        { value: 'ACP', label: 'ACP' },
                        { value: 'PIMD', label: 'PIMD' },
                        { value: 'Other', label: 'Other (Please specify)' },
                      ]}
                      clearable
                      required
                      disabled={loading}
                    />
                  )}
                />
              </Grid.Col>

              {watch('referralsource') === 'Other' && (
                <Grid.Col span={6}>
                  <TextInput
                    label="Please specify"
                    placeholder="Enter the source"
                    {...register('referralsourcecustom', { required: true })}
                    required
                    disabled={loading}
                  />
                </Grid.Col>
              )}
            </Grid>

            <Text mt="xs" size="sm" fw={500}>
              Are You Currently a Member of any of the following?
            </Text>
            <Text mt="xs" size="sm">
              Check all that apply
            </Text>
            <Grid>
              <Grid.Col span={12}>
                <Controller
                  name="affiliations"
                  control={control}
                  render={({ field }) => (
                    <Checkbox.Group {...field}>
                      <Checkbox
                        value="WeCare IPA"
                        label="WeCare IPA"
                        disabled={loading}
                      />
                      <Checkbox
                        value="MSSNY"
                        label="Medical Society of the State of New York"
                        disabled={loading}
                      />
                      <Checkbox
                        value="Other"
                        label="Other IPA"
                        disabled={loading}
                      />
                    </Checkbox.Group>
                  )}
                />
              </Grid.Col>
              {watch('affiliations')?.includes('Other') && (
                <Grid.Col span={12}>
                  <TextInput
                    placeholder="Please type the name of the other IPA"
                    {...register('otherIpaName', { required: true })}
                  />
                </Grid.Col>
              )}
            </Grid>
            {/* Health Insurance Priorities */}
            <Text mt="xs" size="sm" fw={500}>
              {' '}
              {/* Set weight to match other labels */}
              What is the Most Important to You About Your Health Insurance?
            </Text>
            <Text mt="xs" size="sm">
              {' '}
              Drag and drop to rank order your priorities
            </Text>
            <Grid>
              <Grid.Col span={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="priorities" isDropDisabled={loading}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {priorities.map((priority, index) => (
                          <Draggable
                            key={priority.id}
                            draggableId={priority.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  padding: '8px',
                                  margin: '4px 0',
                                  backgroundColor: '#f1f1f1',
                                  borderRadius: '4px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  cursor: 'grab', // Set grab cursor by default
                                  userSelect: 'none', // Prevent text selection
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <span>{priority.label}</span>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid.Col>
            </Grid>
          </Paper>

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3}>Questions or Comments</Title>
            <Text mt="xs" size="sm">
              If you have any questions, comments, concerns about your
              registration or Ryze Health, or simply want to share your
              thoughts, feel free to leave us a message below.
            </Text>
            <Textarea
              placeholder="Please write any questions or comments you have here..."
              minRows={4}
              disabled={loading}
              {...register('message')}
            />
          </Paper>

          <Button type="submit" mt="md" disabled={loading}>
            {loading ? (
              <>
                <Loader color="blue" size="xs" mr="xs" />
                Loading...
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </form>
      ) : (
        <Text>Loading member data...</Text>
      )}
    </Container>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <OptionalWecareMemberProvider>
    <Container mt="xl" mb="xl">
      <PhysicianForm />
    </Container>
  </OptionalWecareMemberProvider>
);
