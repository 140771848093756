import { Modal } from '@mantine/core';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface ViewSBCDocumentProps {
  planId: string;
  opened: boolean;
  onClose: () => void;
}

const ViewSBCDocument = ({ planId, opened, onClose }: ViewSBCDocumentProps) => {
  const planToFileMap: { [key: string]: string } = {
    '1': 'Ryze-SBC-1000-PPO-Plan.pdf',
    '2': 'Ryze-SBC-3000-PPO-Plan.pdf',
    '3': 'Ryze-SBC-5000-PPO-Plan.pdf',
    '4': 'Ryze-SBC-3500-PPO-Plan.pdf',
    '5': 'Ryze-SBC-6000-PPO-Plan.pdf',
  };

  const pdfFile = planId
    ? `${process.env.PUBLIC_URL}/files/${planToFileMap[planId]}`
    : '';

  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size="xxl"
      title={`Summary of Benefits and Coverage - ${planId ? `Plan ${planId}` : ''}`}
      styles={{
        body: {
          overflowY: 'auto',
        },
      }}
    >
      <Modal.Body>
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading PDF..."
          error="Failed to load PDF file."
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={Math.min(window.innerWidth * 0.8, 800)} // Responsive width
              renderTextLayer={true}
              renderAnnotationLayer={true}
            />
          ))}
        </Document>
      </Modal.Body>
    </Modal>
  );
};

export default ViewSBCDocument;
