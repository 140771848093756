import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Title,
  Badge,
  Accordion,
  Loader,
  Pagination,
  Group,
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { Member } from './../../types/member-simple-types';
import NetworkSection from './network-section';
import RequestsSection from './requests-section';
import FindPeopleSection from './find-people-section';
import ReferralsSection from './referrals-section';
import { useFormDirtyAlert } from '../../helpers/useFormDirtyAlert';

// interface Member {
//   id: number;
//   firstname: string;
//   lastname: string;
//   preferredname?: string;
//   specialties: { specialty: string }[];
//   employment: { employer: string; position: string }[];
//   homestate?: string;
//   homezip?: string;
// }

const Community: React.FC = () => {
  const httpClient = useHttpClient();
  const { register, handleSubmit, reset } = useForm();
  // do not use useFormDirtyAlert here

  const navigate = useNavigate();

  //States for your network
  const [connections, setConnections] = useState<Member[]>([]);
  const [yourNetworkCurrentPage, setYourNetworkCurrentPage] = useState(1);
  const [yourNetworkTotalPages, setYournetworkTotalPages] = useState(1);
  const [isYourNetworkLoading, setIsYourNetworkLoading] = useState(false);

  //States for connection requests
  const [requests, setRequests] = useState<Member[]>([]);
  const [requestsCurrentPage, setRequestsCurrentPage] = useState(1);
  const [requestsTotalPages, setRequestsTotalPages] = useState(1);
  const [isRequestsLoading, setIsRequestsLoading] = useState(false);
  const [processedMemberId, setProcessedMemberId] = useState<number | null>(
    null,
  );
  const [loadingAction, setLoadingAction] = useState<
    'accept' | 'reject' | null
  >(null);

  //States for find people
  const [members, setMembers] = useState<Member[] | null>(null);
  const [isConnected, setIsConnected] = useState<Record<number, boolean>>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [findPeopleCurrentPage, setFindPeopleCurrentPage] = useState(1);
  const [findPeopleTotalPages, setFindPeopleTotalPages] = useState(1);
  const [isFindPeopleMembersLoading, setIsFindPeopleMembersLoading] =
    useState(false);

  //States for referrals
  const [isReferralsLoading, setIsReferralsLoading] = useState(false);

  // Fetch your network connections with pagination
  const fetchYourNetworkConnections = async (page = 1) => {
    setIsYourNetworkLoading(true);
    try {
      const response = await httpClient.get(
        `/api/members/community/your-network?page=${page}&limit=8`,
      );

      setConnections(response.data.network);
      setYourNetworkCurrentPage(response.data.pagination.currentPage);
      setYournetworkTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching accepted connections:', error);
    } finally {
      setIsYourNetworkLoading(false);
    }
  };

  // Fetch members to connect with
  const searchMembers = async (page = 1) => {
    setIsFindPeopleMembersLoading(true);
    try {
      const response = await httpClient.get(
        `/api/members/community/members?search=${encodeURIComponent(searchQuery)}&page=${page}&limit=8`,
      );
      setMembers(response.data.members);
      setFindPeopleCurrentPage(response.data.pagination.currentPage);
      setFindPeopleTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error searching members:', error);
    } finally {
      setIsFindPeopleMembersLoading(false);
    }
  };

  // Fetch connection requests
  const fetchConnectionRequests = async (page = 1) => {
    setIsRequestsLoading(true);
    try {
      const response = await httpClient.get(
        `/api/members/community/connection-requests?page=${page}&limit=8`,
      );
      setRequests(response.data.requests);
      setRequestsCurrentPage(response.data.pagination.currentPage);
      setRequestsTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching connection requests:', error);
      notifications.show({
        title: error?.message,
        message: '',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsRequestsLoading(false);
    }
  };

  const addFriend = async (memberId: number) => {
    setProcessedMemberId(memberId);
    try {
      const response = await httpClient.get(
        `/api/members/community/addfriend/${memberId}`,
      );
      setIsConnected((prev) => ({ ...prev, [memberId]: true }));
      notifications.show({
        title: 'Request sent!',
        message: '',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error: any) {
      console.error('Error connecting to member', error);
      notifications.show({
        title: error?.message,
        message: '',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setProcessedMemberId(null);
    }
  };

  const handleOnMessageClick = (id) => {
    navigate(`/wecare/messages?id=${id}`);
  };

  const handleReferralSubmit = () => {};

  const handleAcceptRequest = async (
    connectionId: number,
    memberId: number,
  ) => {
    setProcessedMemberId(memberId);
    setLoadingAction('accept');
    try {
      const response = await httpClient.put(
        `/api/members/community/accept-request/${connectionId}`,
      );
      if (response.data) {
        notifications.show({
          title: 'Connection request accepted!',
          message: '',
          color: 'green',
          position: 'bottom-center',
        });
      }
      setRequests((prevRequests) =>
        prevRequests.filter((request) => request.connectionId !== connectionId),
      );
    } catch (error) {
      console.error('Error accepting connection', error);
      notifications.show({
        title: error?.message,
        message: '',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setProcessedMemberId(null);
      setLoadingAction(null);
    }
  };

  const handleRejectRequest = async (
    connectionId: number,
    memberId: number,
  ) => {
    setProcessedMemberId(memberId);
    setLoadingAction('reject');
    try {
      const response = await httpClient.put(
        `/api/members/community/reject-request/${connectionId}`,
      );
      if (response.data) {
        notifications.show({
          title: 'Connection request rejected!',
          message: '',
          color: 'red',
          position: 'bottom-center',
        });
      }
      setRequests((prevRequests) =>
        prevRequests.filter((request) => request.connectionId !== connectionId),
      );
    } catch (error) {
      console.error('Error accepting connection', error);
    } finally {
      setProcessedMemberId(null);
      setLoadingAction(null);
    }
  };

  useEffect(() => {
    fetchYourNetworkConnections();
  }, [loadingAction]);

  useEffect(() => {
    fetchConnectionRequests();
  }, [isConnected]);

  return (
    <Container size="xl" py="md">
      <Accordion defaultValue="item1">
        <Accordion.Item value="item1">
          <Accordion.Control>
            <Title order={3}>Your Network</Title>
          </Accordion.Control>
          <Accordion.Panel>
            {isYourNetworkLoading ? (
              <Loader size="lg" />
            ) : (
              <>
                <NetworkSection
                  connections={connections}
                  onMessageClick={handleOnMessageClick}
                />
                <Group justify={'flex-end'}>
                  <Pagination
                    total={yourNetworkTotalPages}
                    value={yourNetworkCurrentPage}
                    onChange={(page) => fetchYourNetworkConnections(page)}
                    size="md"
                  />
                </Group>
              </>
            )}
          </Accordion.Panel>
        </Accordion.Item>

        {requests.length > 0 && (
          <Accordion.Item value="item2">
            <Accordion.Control>
              <Title order={3}>
                Connection Requests
                <Badge variant="light" color="red" ml="md">
                  {requests.length} pending
                </Badge>
              </Title>
            </Accordion.Control>
            <Accordion.Panel>
              {isRequestsLoading ? (
                <Loader size="lg" />
              ) : (
                <>
                  <RequestsSection
                    requests={requests}
                    onAccept={handleAcceptRequest}
                    onReject={handleRejectRequest}
                    processedMemberId={processedMemberId}
                    loadingAction={loadingAction}
                  />
                  <Group justify={'flex-end'}>
                    <Pagination
                      total={requestsTotalPages}
                      value={requestsCurrentPage}
                      onChange={(page) => fetchConnectionRequests(page)}
                      size="md"
                    />
                  </Group>
                </>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        )}

        <Accordion.Item value="item3">
          <Accordion.Control>
            <Title order={3}>Find People</Title>
          </Accordion.Control>
          <Accordion.Panel>
            {isFindPeopleMembersLoading ? (
              <Loader size="lg" />
            ) : (
              <>
                <FindPeopleSection
                  members={members}
                  isLoading={isFindPeopleMembersLoading}
                  searchQuery={searchQuery}
                  isConnected={isConnected}
                  onSearchChange={setSearchQuery}
                  onSearch={searchMembers}
                  onConnect={addFriend}
                  processedMemberId={processedMemberId}
                />
                {members && (
                  <Group justify={'flex-end'}>
                    <Pagination
                      total={findPeopleTotalPages}
                      value={findPeopleCurrentPage}
                      onChange={(page) => searchMembers(page)}
                      size="md"
                    />
                  </Group>
                )}
              </>
            )}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="item4">
          <Accordion.Control>
            <Title order={3}>Referrals</Title>
          </Accordion.Control>
          <Accordion.Panel>
            <ReferralsSection
              isLoading={isReferralsLoading}
              register={register}
              onSubmit={handleReferralSubmit}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default Community;
