import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';
import useHttpClient from './hooks/useHttpClient';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';

interface FormProps {
  memberId: number;
}

const ReferenceForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm({});

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'references',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();
  const httpClient = useHttpClient();

  const fetchReferences = async () => {
    try {
      const response = await httpClient.get(`/api/references/${memberId}`);
      const fetchedReferences = response.data;

      const sortedReferences = fetchedReferences.sort((a: any, b: any) => {
        const lastNameComparison = a.lastname.localeCompare(b.lastname);
        if (lastNameComparison !== 0) return lastNameComparison;
        const firstNameComparison = a.firstname.localeCompare(b.firstname);
        if (firstNameComparison !== 0) return firstNameComparison;
        return a.id - b.id;
      });

      reset({ references: sortedReferences });
      setInitialData(sortedReferences);
    } catch (error) {
      console.error('Error fetching references:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch references',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    fetchReferences();
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('submit form values', data);

    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
      }));
    };

    const formattedData = formatData(data.references);

    const createReference = async (reference: any) => {
      const response = await httpClient.post('/api/references', reference);
      return response.data;
    };

    const updateReference = async (reference: any) => {
      const response = await httpClient.put(
        `/api/references/${reference.id}`,
        reference,
      );
      return response.data;
    };

    const deleteReference = async (id: number) => {
      await httpClient.delete(`/api/references/${id}`);
      console.log('delete response');
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(initialData?.map((r: any) => r.id));
    const currentIds = new Set<number>(formattedData.map((r: any) => r.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((r: any) => !r.id || r.id === 0)
      .map((r: any) => createReference(r));
    const updateTasks = formattedData
      .filter((r: any) => r.id && r.id > 0 && initialIds.has(r.id))
      .map((r: any) => updateReference(r));

    const deleteTasks = deletedIds.map((id) => deleteReference(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchReferences();

      notifications.show({
        title: 'Success',
        message: 'References updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting reference form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit reference',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Reference {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.providertype`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Provider Type"
                        data={[
                          { value: 'physician', label: 'Physician' },
                          { value: 'other', label: 'Other medical provider' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    {...register(`references.${index}.firstname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    {...register(`references.${index}.lastname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 1'}
                    {...register(`references.${index}.street1`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 2'}
                    {...register(`references.${index}.street2`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="City"
                    {...register(`references.${index}.city`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={[
                          'AK',
                          'AL',
                          'AR',
                          'AS',
                          'AZ',
                          'CA',
                          'CO',
                          'CT',
                          'DC',
                          'DE',
                          'FL',
                          'GA',
                          'GU',
                          'HI',
                          'IA',
                          'ID',
                          'IL',
                          'IN',
                          'KS',
                          'KY',
                          'LA',
                          'MA',
                          'MD',
                          'ME',
                          'MI',
                          'MN',
                          'MO',
                          'MP',
                          'MS',
                          'MT',
                          'NC',
                          'ND',
                          'NE',
                          'NH',
                          'NJ',
                          'NM',
                          'NV',
                          'NY',
                          'OH',
                          'OK',
                          'OR',
                          'PA',
                          'PR',
                          'RI',
                          'SC',
                          'SD',
                          'TN',
                          'TX',
                          'UM',
                          'UT',
                          'VA',
                          'VI',
                          'VT',
                          'WA',
                          'WI',
                          'WV',
                          'WY',
                        ].map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Province"
                    {...register(`references.${index}.province`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="ZIP"
                    {...register(`references.${index}.zip`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.country`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Email Address"
                    {...register(`references.${index}.emailaddress`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.phone`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Phone">
                        <Input
                          component={IMaskInput}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                          {...field}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.fax`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Fax">
                        <Input
                          component={IMaskInput}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                          {...field}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                providertype: '',
                firstname: '',
                lastname: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                province: '',
                zip: '',
                country: 'US',
                emailaddress: '',
                phone: '',
                fax: '',
              })
            }
          >
            Add Reference
          </Button>
        </Group>
        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default ReferenceForm;
