import {
  IconHome2,
  IconCalendarStats,
  IconUser,
  IconSettings,
  IconLogout,
  IconDashboard,
  IconUsersGroup,
  IconUsers,
  IconUsersPlus,
  IconMessage,
  IconMessages,
  IconLetterRSmall,
} from '@tabler/icons-react';

// Determines the menu based on the current path, supporting regex routes.
// Matches a route in `mainMenuList`, defaults to wildcard if no match.
// Uses the admin layout exclusively for the root path ('/').

export const mainMenuList = [
  {
    layout: 'admin',
    routes: [
      '/',
      '/admin', // use 1 segment path if everything there uses the same menu
      /^\/member\/\d+$/, // matches /member/:id
      '/insurance/admin',
      '/admin/settings',
    ],
    title: 'Admin Portal',
    menu: [
      {
        icon: IconDashboard,
        label: 'Dashboard',
        path: '/admin/dashboard',
      },
      // {
      //   icon: IconUsersGroup,
      //   label: 'Members',
      //   path: '/insurance/admin/members',
      // },
      {
        icon: IconUser,
        label: 'Member Approval',
        path: '/insurance/admin/approve',
      },
      {
        icon: IconCalendarStats,
        label: 'Register Physician',
        path: '/admin/register/physician',
      },
      {
        icon: IconCalendarStats,
        label: 'Register Organization',
        path: '/admin/register/organization',
      },
      { icon: IconSettings, label: 'Settings', path: '/admin/settings' },
      { icon: IconLogout, label: 'Logout', path: '/logout' },
    ],
  },
  {
    layout: 'organization',
    routes: ['/insurance/clinic', '/insurance/clinic/settings'],
    title: 'Clinic Portal',
    menu: [
      {
        icon: IconDashboard,
        label: 'Dashboard',
        path: '/insurance/clinic/dashboard',
      },
      {
        icon: IconUser,
        label: 'Clinic Portal',
        path: '/insurance/clinic',
      },
      {
        icon: IconUser,
        label: 'Complete Registration',
        path: '/insurance/clinic/employee',
        requiredPrivileges: ['insurance_employee'],
      },
      {
        icon: IconSettings,
        label: 'Settings',
        path: '/insurance/clinic/settings',
      },
      { icon: IconLogout, label: 'Logout', path: '/logout' },
    ],
  },
  {
    layout: 'member',
    routes: ['/insurance/member', '/insurance/member/settings'],
    title: 'Member Portal',
    menu: [
      {
        icon: IconDashboard,
        label: 'Dashboard',
        path: '/insurance/member/dashboard',
      },
      {
        icon: IconUser,
        label: 'Complete Registration',
        path: '/insurance/member',
      },
      {
        icon: IconSettings,
        label: 'Settings',
        path: '/insurance/member/settings',
      },
      { icon: IconLogout, label: 'Logout', path: '/logout' },
    ],
  },
  {
    layout: 'employee',
    routes: ['/insurance/employee', '/insurance/employee/settings'],
    title: 'Employee Portal',
    menu: [
      // {
      //   icon: IconDashboard,
      //   label: 'Dashboard',
      //   path: '/insurance/employee/dashboard',
      // },
      {
        icon: IconUser,
        label: 'Complete Registration',
        path: '/insurance/employee',
      },
      {
        icon: IconSettings,
        label: 'Settings',
        path: '/insurance/employee/settings',
      },
      { icon: IconLogout, label: 'Logout', path: '/logout' },
    ],
  },
  {
    layout: 'wecareadmin',
    routes: [
      '/wecare/admin',
      '/member',
      '/wecare/admin/approve',
      '/wecare/admin/settings',
      /^\/wecare\/member\/\d+$/,
    ],
    title: 'WeCare Portal',
    menu: [
      {
        icon: IconHome2,
        label: 'Home',
        path: '/wecare/admin',
      },
      {
        icon: IconUsersPlus,
        label: 'Admin Approve',
        path: '/wecare/admin/approve',
      },
      { icon: IconSettings, label: 'Settings', path: '/wecare/admin/settings' },
      { icon: IconLogout, label: 'Logout', path: '/logout' },
    ],
  },
  {
    layout: 'wecaremember',
    routes: [
      '/wecare/member',
      '/wecare/member/settings',
      '/wecare/community',
      '/wecare/messages',
    ],
    title: 'WeCare',
    menu: [
      {
        icon: IconHome2,
        label: 'Home',
        path: '/wecare/member/dashboard',
      },
      {
        icon: IconHome2,
        label: 'My Profile',
        path: '/wecare/member',
      },
      {
        icon: IconUsersGroup,
        label: 'Community',
        path: '/wecare/community',
      },
      {
        icon: IconMessages,
        label: 'Messages',
        path: '/wecare/messages',
      },
      {
        icon: IconLetterRSmall,
        label: 'Signup for Ryze',
        path: 'https://www.ryzehealth.com/join',
        external: true,
      },
      {
        icon: IconSettings,
        label: 'Settings',
        path: '/wecare/member/settings',
      },
      { icon: IconLogout, label: 'Logout', path: '/logout' },
    ],
  },
];

export const subMenuList = {
  member: {
    hidden: false,
    menu: [
      { key: 'personal-info', value: 'Personal Info' }, // #TODO: confirm this // member
      { key: 'specialties', value: 'Specialties' },
      { key: 'practices', value: 'Practice Locations' },
      { key: 'hospitals', value: 'Hospitals' },
      { key: 'professionalIds', value: 'Professional IDs' },
      { key: 'certifications', value: 'Certifications' },
      { key: 'education', value: 'Education & Professional Training' },
      { key: 'liabilities', value: 'Liability Coverage' },
      { key: 'credentialingContacts', value: 'Credentialing Contacts' },
      { key: 'employment', value: 'Employment History' },
      { key: 'references', value: 'Professional References' },
      { key: 'disclosures', value: 'Disclosures' },
      { key: 'uploadedDocuments', value: 'Uploaded Documents' },
    ],
  },
  clinic: {
    hidden: true,
    menu: [
      { key: 'practice-info', value: 'Practice Information' },
      { key: 'employees', value: 'Manage Employees' },
      { key: 'next-steps', value: 'Next Steps' },
      { key: 'payment-information', value: 'Payment Information' },
      // { key: 'financial-statements', value: 'Financial Statements' },
      // { key: 'settings', value: 'Settings' },
    ],
    allowSwitchingBetweenPages: false,
  },
  independentPhysician: {
    hidden: true,
    menu: [
      { key: 'personal-info', value: 'Personal Info' },
      { key: 'family-member-management', value: 'Family Member Management' },
      { key: 'priorities', value: 'Priorities' },
      { key: 'plan-selection', value: 'Plan Selection' },
      { key: 'payment-information', value: 'Payment Information' },
    ],
    allowSwitchingBetweenPages: false,
  },
  employee: {
    hidden: true,
    menu: [
      { key: 'personal-info', value: 'Personal Info' },
      { key: 'family-member-management', value: 'Family Member Management' },
      { key: 'priorities', value: 'Priorities' },
      { key: 'plan-selection', value: 'Plan Selection' },
    ],
    allowSwitchingBetweenPages: false,
  },
};
