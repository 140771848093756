import React from 'react';
import { Modal, Text, Stack, Title } from '@mantine/core';

interface ThankYouModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  title2?: string;
  description: string;
  description2?: string;
}

const ThankYouModal: React.FC<ThankYouModalProps> = ({
  opened,
  onClose,
  title,
  title2,
  description,
  description2,
}) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={'lg'}
      centered
      styles={{
        header: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
        body: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
        content: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
      }}
    >
      <Modal.Title
        my={20}
        style={{
          textAlign: 'center',
        }}
      >
        <Title order={1} fw={'bold'} c="#3f29cd">
          {title}
        </Title>
        <Title order={1} fw={'bold'} c="#3f29cd">
          {title2}
        </Title>
      </Modal.Title>
      <Modal.Body
        style={{
          textAlign: 'center',
        }}
      >
        <Text size="md" fw={500}>
          {description}
        </Text>
        <Text size="md" fw={500}>
          {description2}
        </Text>
      </Modal.Body>
    </Modal>
  );
};

export default ThankYouModal;
