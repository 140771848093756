import React from 'react';
import { Grid, Group, Card, Text, Button, Badge } from '@mantine/core';
import { IconMessageCircle } from '@tabler/icons-react';
import { RyzeAvatar } from '../AvatarUploader';
import { Member } from './../../types/member-simple-types';
interface NetworkSectionProps {
  connections: Member[];
  onMessageClick: (memberId: number) => void;
}

const NetworkSection: React.FC<NetworkSectionProps> = ({
  connections,
  onMessageClick,
}) => {
  if (connections.length === 0) {
    return (
      <Group justify="left" p={10}>
        You don't have any connections yet...
      </Group>
    );
  }

  return (
    <Grid mb="xl">
      {connections.map((member) => (
        <Grid.Col key={member.id} span={{ base: 6, md: 4, lg: 3 }}>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            style={{
              height: '100%',
            }}
          >
            <Card.Section withBorder inheritPadding py="xs">
              <Group justify="center">
                <RyzeAvatar
                  color="blue"
                  radius={60}
                  size={120}
                  memberId={member.id}
                >
                  {member.firstname[0] + member.lastname[0]}
                </RyzeAvatar>
              </Group>
              <Group justify="center" mt={10}>
                <Text
                  fw={700}
                  style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                  }}
                >
                  {member.preferredname ||
                    `${member.firstname} ${member.lastname}`}
                </Text>
              </Group>
              <Group justify="center">
                {member.employment[0] && (
                  <Text size="sm" c="dimmed">
                    {member.employment[0].position} at{' '}
                    {member.employment[0].employer}
                  </Text>
                )}
              </Group>
            </Card.Section>

            <Card.Section py="md">
              <Group justify="center" gap="xs">
                {member.specialties.slice(0, 3).map((specialty, index) => (
                  <Badge key={index} variant="light" color="blue">
                    {specialty.specialty}
                  </Badge>
                ))}
              </Group>
            </Card.Section>

            <Button
              fullWidth
              variant="light"
              color="blue"
              leftSection={<IconMessageCircle size={16} />}
              onClick={() => onMessageClick(member.id)}
            >
              Send a Message
            </Button>
          </Card>
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default NetworkSection;
