// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../AuthProvider';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Grid,
  Group,
  Title,
  Table,
  LoadingOverlay,
  ActionIcon,
  NavLink,
  Tooltip,
  Text,
  Modal,
  Divider,
  Flex,
  Loader,
  Select,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { Plus, Upload } from 'tabler-icons-react';
import useHttpClient from '../hooks/useHttpClient';
import { useOrganizationContext } from './OrganizationContext';
import './css/EmployeeManagementTab.css';
import { OrganizationMember } from '../../types/subscribermember.types';
import CustomDateInput from '../CustomDateInput';
import {
  IconUser,
  IconOld,
  IconPlus,
  IconFileTypeCsv,
  IconCircle,
  IconInfoCircle,
  IconUserShield,
  IconCsv,
  IconClock,
  IconWoman,
  IconBabyCarriage,
} from '@tabler/icons-react';
import { Trash, Info } from 'tabler-icons-react';
import { notifications } from '@mantine/notifications';
import { IMaskInput } from 'react-imask';
import EmployeeListUpload from './EmployeeListUpload';

interface ExtendedOrganizationMember extends OrganizationMember {
  memberId?: number;
  familyMembers?: ExtendedOrganizationMember[];
}
interface FormValues {
  members: ExtendedOrganizationMember[];
}
const EmployeeManagementTab: React.FC = () => {
  const { organizationId } = useOrganizationContext();
  const { control, register, reset } = useForm<FormValues>({
    defaultValues: { members: [] },
  });
  const [fields, setFields] = useState<ExtendedOrganizationMember[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingNewMember, setIsAddingNewMember] = useState(false);
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();
  const [selectedMember, setSelectedMember] =
    useState<ExtendedOrganizationMember | null>(null);
  const [filter, setFilter] = useState<string>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCsvUploadModal, setShowCsvUploadModal] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [employeeRecordExists, setEmployeeRecordExists] = useState(false);

  const { logoutAuth } = useAuth(); //Remove when token refresh is implemented

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const response = await httpClient.get(`/api/organization/`);
        setOrganizationDetails(response.data);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch organization details',
          color: 'red',
          position: 'bottom-center',
        });
      }
    };

    fetchOrganizationDetails();
  }, [httpClient]);

  // Check if a userprivileges record exists for this organization's email
  useEffect(() => {
    if (organizationDetails && organizationDetails.email) {
      httpClient
        .get(
          `/api/user/exists?email=${encodeURIComponent(
            organizationDetails.email,
          )}&type=insurance_employee`,
        )
        .then((response) => {
          setEmployeeRecordExists(response.data.exists);
        })
        .catch((error) => {
          console.error('Error checking employee record:', error);
        });
    }
  }, [organizationDetails, httpClient]);

  const fetchMembers = async () => {
    try {
      setLoading(true);
      const response = await httpClient.get(`/api/subscriber/organization/`);
      const fetchedMembers: OrganizationMember[] = response.data;

      const mappedMembers: ExtendedOrganizationMember[] = fetchedMembers.map(
        (member) => ({
          ...member,
          memberId: member.id,
          id: undefined,
        }),
      );

      const membersWithFamilies = mappedMembers
        .filter(
          (member) =>
            member.primarysubscriber === null &&
            member.enrollmentstatus !== 'Removed',
        )
        .map((primaryMember) => ({
          ...primaryMember,
          familyMembers: mappedMembers
            .filter(
              (member) =>
                member.primarysubscriber === primaryMember.memberId &&
                member.enrollmentstatus !== 'Removed',
            )
            .sort((a, b) => {
              const relationshipOrder = ['Primary', 'Spouse', 'Child'];
              return (
                relationshipOrder.indexOf(a.subscriberrelationship) -
                relationshipOrder.indexOf(b.subscriberrelationship)
              );
            }),
        }));
      reset({ members: membersWithFamilies });
      setFields(membersWithFamilies);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch members',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = () => {
    setSelectedMember({
      firstname: '',
      lastname: '',
      primarysubscriber: null,
      familyMembers: [],
      subscriberrelationship: 'Primary',
      enrollmentstatus: 'Eligible',
      email: null,
    });
    setIsAddingNewMember(true);
    setIsEditing(true);
  };

  const handleApproval = async (
    status: string,
    primarySubscriberId: number,
  ) => {
    try {
      const { data } = await httpClient.put(
        `/api/subscriber/${primarySubscriberId}/approve-family`,
        { status },
      );
      notifications.show({
        title: 'Success',
        message: `Family ${status.toLowerCase()} successfully`,
        color: 'green',
        position: 'bottom-center',
      });
      await fetchMembers(); // Refresh the member list after approval/denial
      setSelectedMember((member) => {
        return { ...member, enrollmentstatus: status };
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: `Failed to ${status.toLowerCase()} family`,
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const handleRemoveFamily = async (primarySubscriberId: number) => {
    try {
      const response = await httpClient.put(
        `/api/subscriber/${primarySubscriberId}/remove-family`,
        { status: 'Removed' },
      );
      notifications.show({
        title: 'Success',
        message: 'Family removed successfully',
        color: 'yellow',
        position: 'bottom-center',
      });
      fetchMembers(); // Refresh the member list after removal
      setSelectedMember(null);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
      notifications.show({
        title: 'Error',
        message: 'Failed to remove family',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const handleAddMyselfEmployee = async () => {
    if (!organizationDetails) {
      notifications.show({
        title: 'Error',
        message: 'Organization details not loaded yet',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }
    try {
      const response = await httpClient.post(
        '/api/subscriber/enrollment/add-self',
        {
          firstname: organizationDetails.adminfirstname,
          lastname: organizationDetails.adminlastname,
          email: organizationDetails.email,
        },
      );
      notifications.show({
        title: 'Success',
        message: 'Added as employee user. Log back in to view.',
        color: 'green',
        position: 'bottom-center',
      });
      logoutAuth(); //Replace with token refresh and page reload when token refresh is implemented
    } catch (error) {
      notifications.show({
        title: 'Error',
        message:
          'Failed to add self as an employee. If you have already added self as employee, click on the Complete Registration tab on the right to continue.',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'Approved':
        return 'member-approved';
      case 'Denied':
        return 'member-denied';
      default:
        return '';
    }
  };

  const {
    control: memberControl,
    register: memberRegister,
    handleSubmit: memberHandleSubmit,
    setValue: setValue,
    reset: memberReset,
    formState: { isDirty: memberIsDirty },
  } = useForm<ExtendedOrganizationMember>({
    defaultValues: selectedMember,
  });

  const onSubmit = async (data: ExtendedOrganizationMember) => {
    setLoading(true);
    const { familyMembers, ...memberData } = data;

    if (isAddingNewMember && !memberData.id) {
      console.log('memberData new', memberData);

      try {
        delete memberData.memberId;
        await httpClient.post('/api/subscriber/employee', {
          ...memberData,
          organizationId,
        });
        notifications.show({
          title: 'Success',
          message: 'New member added successfully',
          color: 'green',
          position: 'bottom-center',
        });
        setIsAddingNewMember(false);
        setSelectedMember(null);
        fetchMembers();
      } catch (error) {
        console.log(error);
        notifications.show({
          title: 'Error',
          message: 'Failed to add new member. ' + error?.response?.data,
          color: 'red',
          position: 'bottom-center',
        });
      }
    } else {
      try {
        await httpClient.put(`/api/subscriber/${memberData.memberId}`, {
          ...memberData,
          memberId: undefined,
        });
        notifications.show({
          title: 'Success',
          message: 'Member updated successfully',
          color: 'green',
          position: 'bottom-center',
        });
        fetchMembers();
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to update member',
          color: 'red',
          position: 'bottom-center',
        });
      }
    }
    setIsEditing(false);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedMember) {
      memberReset(selectedMember);
    }
  }, [selectedMember, memberReset]);

  useEffect(() => {
    if (organizationId) {
      fetchMembers();
    }
  }, [organizationId]);

  const handleSetFilter = (filterText) => {
    setFilter(filterText);
  };

  const cancel = (e?: React.MouseEvent) => {
    if (isSubmitting) return;
    fetchMembers();
    setIsEditing((prev) => !prev);
  };

  return (
    <Container>
      <Title order={1} style={{ textAlign: 'center' }}>
        Employee Management
      </Title>
      <br />

      <LoadingOverlay visible={loading} />
      <Grid>
        <Grid.Col span={4}>
          <Paper withBorder shadow="xs" p="md">
            <IconUserShield size="64" />
            <Title order={3} mb={10}>
              Manage All Employees
            </Title>
            <Text m={0} size="sm">
              Use this area to enter the employees of your organization. For
              each employee entered they will receive an email from us to enter
              their dependents on this screen. Proceed after approving all
              employees and dependents
            </Text>
            <Divider m={20} />
            <NavLink
              onClick={() => {
                setShowCsvUploadModal(true);
              }}
              label={
                <>
                  <Text color="blue">
                    <IconCsv size="16" /> Upload CSV
                  </Text>
                </>
              }
            />
            <NavLink
              onClick={() => {
                handleAddMember();
              }}
              label={
                <>
                  <Text color="blue">
                    <IconPlus size="16" /> Add Member
                  </Text>
                </>
              }
            />
            <NavLink
              onClick={() => {
                handleSetFilter(null);
              }}
              label={
                <>
                  <Text color="blue">
                    <IconUser size="16" /> Manage Users
                  </Text>
                </>
              }
            />
            <NavLink
              onClick={() => handleSetFilter('Registration Complete')}
              label={
                <>
                  <Text color="blue">
                    <IconClock size="16" /> Users awaiting approval
                  </Text>
                </>
              }
            />
            {!employeeRecordExists && (
              <NavLink
                onClick={handleAddMyselfEmployee}
                label={
                  <Text color="blue" weight={700}>
                    <IconPlus
                      size={16}
                      strokeWidth={3}
                      style={{ marginRight: 5 }}
                    />
                    Add Myself as an Employee
                  </Text>
                }
              />
            )}
          </Paper>
        </Grid.Col>
        <Grid.Col span={8}>
          <Paper withBorder shadow="xs" p="md">
            <Table highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th style={{ width: '50%' }}>Name</Table.Th>
                  {/* <Table.Th>Status</Table.Th> */}
                  <Table.Th style={{ width: '0%' }}>Action</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {fields
                  .filter((member) => {
                    return !filter || member.enrollmentstatus === filter;
                  })
                  .map((member) => {
                    const memberKey = member.id || member.memberId;
                    const statusClass = getStatusClass(member.enrollmentstatus);

                    const familyMembers =
                      member.familyMembers &&
                      member.familyMembers
                        .filter((member) => {
                          return !filter || member.enrollmentstatus === filter;
                        })
                        .map((member) => {
                          const memberKey = member.id || member.memberId;
                          return (
                            <Table.Tr
                              href="#employees"
                              onClick={() => {
                                if (!isEditing) {
                                  setSelectedMember(member);
                                  setIsEditing(true);
                                }
                              }}
                              className={`family-member-row ${statusClass}`}
                            >
                              <Table.Td>
                                {member.subscriberrelationship ===
                                  'Dependent' && (
                                  <IconOld
                                    size="1rem"
                                    stroke={1.5}
                                    style={{
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                    }}
                                  />
                                )}
                                {member.subscriberrelationship === 'Spouse' && (
                                  <IconWoman
                                    size="1rem"
                                    stroke={1.5}
                                    style={{
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                    }}
                                  />
                                )}
                                {member.subscriberrelationship === 'Child' && (
                                  <IconBabyCarriage
                                    size="1rem"
                                    stroke={1.5}
                                    style={{
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                    }}
                                  />
                                )}
                                {member.firstname + ' ' + member.lastname}
                              </Table.Td>

                              {/* <Table.Td>{member.enrollmentstatus}</Table.Td> */}
                              <Table.Td>
                                {(!!member.primarysubscriber ||
                                  member.memberId) && (
                                  <ActionIcon
                                    color="red"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveFamily(member.memberId);
                                    }}
                                    variant="transparent"
                                    style={{ float: 'right' }}
                                  >
                                    <Trash size={16} />
                                  </ActionIcon>
                                )}
                              </Table.Td>
                            </Table.Tr>
                          );
                        });

                    return (
                      <>
                        <Table.Tr
                          style={{ cursor: 'pointer' }}
                          key={memberKey}
                          onClick={() => {
                            if (!isEditing) {
                              setSelectedMember(member);
                              setIsEditing(true);
                            }
                          }}
                          className={`member-row ${statusClass}`}
                        >
                          <Table.Td>
                            <IconUser
                              size="1rem"
                              stroke={1.5}
                              style={{ marginRight: '10px' }}
                            />
                            {member.firstname + ' ' + member.lastname}
                          </Table.Td>
                          {/* <Table.Td>{member.enrollmentstatus}</Table.Td> */}
                          <Table.Td>
                            {(!!member.primarysubscriber ||
                              member.memberId) && (
                              <ActionIcon
                                color="red"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFamily(member.memberId);
                                }}
                                variant="transparent"
                                style={{ float: 'right' }}
                              >
                                <Trash size={16} />
                              </ActionIcon>
                            )}
                          </Table.Td>
                        </Table.Tr>
                        {familyMembers}
                      </>
                    );
                  }) || (
                  <Table.Tr>
                    <Table.Td>XX</Table.Td>
                    <Table.Td>XX</Table.Td>
                    <Table.Td>XX</Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          </Paper>
        </Grid.Col>
      </Grid>

      <Modal
        opened={isEditing}
        onClose={cancel}
        size="xl"
        title={
          <h2 style={{ margin: 0 }}>
            {!selectedMember?.memberId ? 'Add New ' : ''}
            Employee Member
          </h2>
        }
      >
        <Grid>
          {[
            // 'New',
            'Registration Complete',
            'Approved',
            'Denied',
          ].includes(selectedMember?.enrollmentstatus) ? (
            <Grid.Col span="auto">
              <Group position="center">
                <Button
                  color="green"
                  disabled={selectedMember.enrollment === 'Approved'}
                  onClick={() =>
                    handleApproval('Approved', selectedMember.memberId)
                  }
                >
                  Approve Employees
                </Button>
                <Button
                  color="red"
                  variant="filled"
                  disabled={selectedMember.enrollmentstatus === 'Denied'}
                  onClick={() =>
                    handleApproval('Denied', selectedMember.memberId)
                  }
                >
                  Deny Employees
                </Button>
              </Group>
            </Grid.Col>
          ) : null}
          {selectedMember?.memberId && (
            <Grid.Col span="content">
              <Button
                variant="outline"
                color="red"
                onClick={() => handleRemoveFamily(selectedMember.memberId)}
              >
                Remove{' '}
                {selectedMember?.subscriberrelationship === 'Primary'
                  ? 'Employee'
                  : selectedMember?.subscriberrelationship}
              </Button>
            </Grid.Col>
          )}
        </Grid>

        {selectedMember && (
          <form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onSubmit={memberHandleSubmit(onSubmit)}
          >
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Grid justify="space-between" align="center">
                <Grid.Col span={7}>
                  <Title order={3}>
                    {selectedMember.firstname} {selectedMember.lastname}
                  </Title>
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    {...memberRegister('firstname', { required: true })}
                    disabled={!isEditing && !isAddingNewMember}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    {...memberRegister('lastname', { required: true })}
                    disabled={!isEditing && !isAddingNewMember}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={memberControl}
                    label="Date of Birth"
                    disabled={!isEditing && !isAddingNewMember}
                    {...register('birthdate', {
                      onChange: (e) => {
                        console.log('Custom onChange event:', e.target.value);
                      },
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="sex"
                    control={memberControl}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Sex at Birth"
                        data={[
                          { value: 'M', label: 'M' },
                          { value: 'F', label: 'F' },
                        ]}
                        clearable
                        disabled={!isEditing}
                      />
                    )}
                  />
                </Grid.Col>
                {selectedMember.subscriberrelationship === 'Primary' && (
                  <Grid.Col span={6}>
                    <TextInput
                      label="Email"
                      {...memberRegister('email', { required: true })}
                      disabled={!isEditing && !isAddingNewMember}
                      required
                    />
                  </Grid.Col>
                )}

                <Grid.Col span={6}>
                  <TextInput
                    label="Enrollment Status"
                    {...memberRegister('enrollmentstatus', {
                      required: true,
                    })}
                    disabled
                  />
                </Grid.Col>
              </Grid>

              {isEditing && (
                <Flex justify="flex-end">
                  <Button
                    disabled={isSubmitting}
                    color="red"
                    mt="md"
                    style={{ marginRight: '5px' }}
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                  <Button disabled={isSubmitting} type="submit" mt="md">
                    {isSubmitting ? (
                      <>
                        <Loader color="blue" size="xs" mr="xs" />
                        Loading...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Flex>
              )}
            </Paper>

            {selectedMember.familyMembers &&
              selectedMember.familyMembers.map((familyMember) => (
                <Paper
                  key={familyMember.memberId}
                  withBorder
                  shadow="xs"
                  p="md"
                  mt="md"
                >
                  <Title order={4}>
                    {familyMember.firstname} {familyMember.lastname}
                  </Title>

                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        value={familyMember.firstname}
                        disabled
                      />
                    </Grid.Col>

                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        value={familyMember.lastname}
                        disabled
                      />
                    </Grid.Col>

                    <Grid.Col span={6}>
                      <TextInput
                        label="Enrollment Status"
                        value={familyMember.enrollmentstatus}
                        disabled
                      />
                    </Grid.Col>
                  </Grid>
                  {/* {isEditing && (
                    <Button type="submit" mt="md">
                      Save
                    </Button>
                  )} */}
                </Paper>
              ))}
          </form>
        )}
      </Modal>
      <EmployeeListUpload
        employeesUploaded={false}
        practiceId={organizationId}
        modalOpened={showCsvUploadModal}
        setModalOpened={setShowCsvUploadModal}
      />
      {/* <pre>{JSON.stringify(selectedMember, null, 2)}</pre> */}
    </Container>
  );
};

export default EmployeeManagementTab;
