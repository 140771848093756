import React, { useEffect, useState } from 'react';
import { Container, Paper, Title, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';

const IpaMemberEmailVerificationPage: React.FC = () => {
  const { enrollmentcode } = useParams<{ enrollmentcode: string }>();
  const [verificationStatus, setVerificationStatus] = useState<string | null>(
    null,
  );
  const httpClient = useHttpClient();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await httpClient.put(
          `/api/members/emailverification/${enrollmentcode}`,
        );

        if (response.status === 200) {
          setVerificationStatus('success');
          notifications.show({
            title: 'Verification Successful',
            message: 'Your email has been verified successfully.',
            color: 'green',
            position: 'bottom-center',
          });
        } else {
          throw new Error('Verification failed');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationStatus('error');
        notifications.show({
          title: 'Verification Failed',
          message:
            'There was an issue verifying your email. Please try again later.',
          color: 'red',
          position: 'bottom-center',
        });
      }
    };

    if (enrollmentcode) {
      verifyEmail();
    }
  }, [enrollmentcode, httpClient]);

  return (
    <Container>
      <Paper shadow="md" p="xl" withBorder>
        <Title order={2} ta="center" mb="sm">
          {verificationStatus === 'success'
            ? 'Thank you for registering with WeCare IPA!'
            : 'Verifying your email...'}
        </Title>
        {verificationStatus === 'success' ? (
          <Text ta="center">
            We appreciate your interest in joining WeCare IPA. We will review
            your eligibility and reach out to you after approval.
          </Text>
        ) : (
          <Text ta="center">Please wait while we verify your email.</Text>
        )}
      </Paper>
    </Container>
  );
};

export default IpaMemberEmailVerificationPage;
