import { Popover } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import classes from './PlanSelectionDataTable.module.css';
import { IconCheck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

interface PlanSelectionDataTableProps {
  query?: string;
}

export default function HiddenCoveredBenefitsTable({ query }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/hiddenBenefits.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);

  if (!query) return;

  const filteredRecords = data?.filter(
    (e) =>
      e.benefitName?.toLowerCase().includes(query.toLowerCase()) ||
      e.benefitBucket?.toLowerCase().includes(query.toLowerCase()),
  );

  return (
    <>
      <DataTable
        className={classes.hiddenBenefits}
        columns={[
          { accessor: 'benefitName', title: 'Benefit' },
          {
            accessor: 'covered',
            render: (row, idx) => {
              return row['covered'] ? <IconCheck color="blue" /> : '';
            },
          },
          {
            accessor: 'PA',
            title: 'PA',
            render: (row, idx) => {
              return row['PA'] === true ? <IconCheck color="blue" /> : '';
            },
          },
          {
            accessor: 'benefitBucket',
            title: 'Benefit Bucket',
          },
        ]}
        records={[...filteredRecords]}
      />
    </>
  );
}
