import React from 'react';
import { TextInput, Button, Group, Text } from '@mantine/core';
import { UseFormRegister } from 'react-hook-form';

interface ReferralsSectionProps {
  isLoading: boolean;
  register: UseFormRegister<any>;
  onSubmit: () => void;
}

const ReferralsSection: React.FC<ReferralsSectionProps> = ({ isLoading, register, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <Text>Send an email invite to join WeCare</Text>
      <br />
      <TextInput
        label="Email"
        placeholder="Email"
        required
        {...register('email', { required: true })}
        disabled={isLoading}
      />

      <Group justify="flex-end" mt="md">
        <Button type="submit">Send Invite</Button>
      </Group>
    </form>
  );
};

export default ReferralsSection;
