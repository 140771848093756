import {
  Group,
  Button,
  Paper,
  Container,
  Title,
  TextInput,
  Grid,
  PasswordInput,
  Loader,
  Table,
  Divider,
  Text,
  Modal,
  Flex,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';
import { User } from '../types/common.types';
import { IconPlus, IconUsers } from '@tabler/icons-react';
import { Member } from '../types/members.types';

export default function ClinicUsers({ userInfo, organizationId }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {} as any,
  });
  // do not use useFormDirtyAlert here

  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<User[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const httpClient = useHttpClient();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchOrgUsers = async () => {
    setLoading(true);
    try {
      const { data } = await httpClient.get<User[]>('/api/organization/users');
      setMembers(data);
    } catch (error) {
      console.error('Error fetching members', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrgUsers();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await httpClient.post<Member[]>(`/api/organization/user`, {
        ...data,
        organizationId,
        email: data.email.toLowerCase(),
        type: 'organization',
      });
      reset();
      notifications.show({
        title: 'Invite sent',
        message: 'An email was sent to complete enrollment.',
        color: 'green',
        position: 'bottom-center',
      });
      setShowModal(false);
    } catch (error) {
      console.log('>>', error?.message);
      notifications.show({
        title: 'Something went wrong',
        message: error?.response?.data || error?.message,
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title order={3} mb={10}>
        <IconUsers style={{ marginRight: '10px' }} />
        Portal Users
      </Title>
      <Text mb="lg"></Text>
      <Text mb="lg">Here's a list of portal users for this organization.</Text>
      <div
        style={{ maxHeight: '300px', overflowY: 'auto', marginBottom: '20px' }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'rgba(242,242,242)',
                // backgroundColor: '#333',
                zIndex: 1,
              }}
            >
              <Table.Th style={{ width: '60%' }}>Username</Table.Th>
              <Table.Th>Name</Table.Th>
              <Table.Th style={{ width: '10%' }}>Confirmed</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {members?.map((member) => (
              <Table.Tr
                style={{
                  fontWeight:
                    member?.username === userInfo?.email ? 'bold' : 'auto',
                }}
              >
                <Table.Td>
                  {member?.username}{' '}
                  {member?.username === userInfo?.email ? '(You)' : ''}
                </Table.Td>
                <Table.Td>
                  {member.firstname} {member.lastname}
                </Table.Td>
                <Table.Td style={{ textAlign: 'center' }}>
                  {member?.approved ? 'Yes' : 'No'}
                </Table.Td>
              </Table.Tr>
            )) || (
              <Table.Tr>
                <Table.Td>
                  <i>No users found...</i>
                </Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </div>
      <Flex justify="flex-end" direction="row" wrap="wrap">
        <Button onClick={() => setShowModal(true)}>
          <IconPlus />
          &nbsp; Add User
        </Button>
      </Flex>

      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title={<Title order={3}>Add Clinic Portal User</Title>}
        centered
        size="md"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <Grid style={{ background: 'none' }}>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
                disabled={loading} // Disable while loading
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
                disabled={loading} // Disable while loading
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Username (Email)"
                required
                {...register('email', { required: true })}
                disabled={loading} // Disable while loading
              />
            </Grid.Col>
          </Grid>
          <Button color="red" mt="md" mr="xs" disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" mt="md" disabled={loading}>
            {loading ? (
              <>
                <Loader color="blue" size="xs" mr="xs" />
                Loading...
              </>
            ) : (
              'Invite User'
            )}
          </Button>
        </form>
      </Modal>
    </>
  );
}
