import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Custom hook to show alert when form is dirty
export const useFormDirtyAlert = (reset, isDirty, dirtyFields) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [previousLocation, setPreviousLocation] = useState(null);

    const unsavedMessage = 'You have unsaved changes. Are you sure you want to leave?';

    useEffect(() => {
        const currentLocation = window.location.href.replace(window.origin, "");
        const timer = setTimeout(() => {
            setPreviousLocation(currentLocation);
        }, 0);
        return () => clearTimeout(timer);
    }, [location]);

    useEffect(() => {

        const handleBeforeUnload = (event) => {
            if (!isDirty) return;
            event.preventDefault();
            return (event.returnValue = '');
        };

        const handlePopState = (event) => {
            if (!isDirty || Object.keys(dirtyFields).length === 0) return;

            console.log("A dirtyFields", dirtyFields, isDirty, Object.keys(dirtyFields).length);

            const confirmed = window.confirm(unsavedMessage);
            if (!confirmed) {
                setTimeout(() => {
                    navigate(previousLocation, { replace: true })
                }, 0);
            } else {
                reset();
            }
        };

        const replaceState = (state, title, url) => {
            if (isDirty || Object.keys(dirtyFields).length > 0) {

                console.log("B dirtyFields", dirtyFields, isDirty, Object.keys(dirtyFields).length);

                const confirmed = window.confirm(unsavedMessage + "?");
                if (confirmed) {
                    originalReplaceState.apply(window.history, [state, title, url]);
                }
            } else {
                originalReplaceState.apply(window.history, [state, title, url]);
            }
        };

        const originalReplaceState = window.history.replaceState;

        window.history.pushState = replaceState;
        window.onbeforeunload = handleBeforeUnload;
        window.onpopstate = handlePopState;

        return () => {
            window.history.pushState = originalReplaceState;
            window.onbeforeunload = null;
            window.onpopstate = null;
        };
    }, [isDirty, window]);
};