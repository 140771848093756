import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  TextInput,
  Button,
  ScrollArea,
  Group,
  Text,
  Box,
  Grid,
  Loader,
  ActionIcon,
  Flex,
} from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';
import { useLocation } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { RyzeAvatar } from '../AvatarUploader';
import { IconSend, IconEdit, IconDots, IconTrash } from '@tabler/icons-react';
import { useAuth } from '../AuthProvider';
import { Conversation } from '../../types/conversation.types';
import { io } from 'socket.io-client';
import ConnectionsListbox from './connections-listbox';
import DragAndDropUpload, {
  RyzeMessageAttachment,
} from './drag-and-drop-upload';
import EditGroupNameModal from './edit-group-name-modal';
import OptionsModal from './options-modal';
import { parseMessage } from '../../helpers/stringUtils';
import DeleteConfirmationModal from './delete-confirmation-modal';
import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core';

const MessengerPage = () => {
  const computedColorScheme = useComputedColorScheme('light');
  const [isConversationLoading, setIsConversationLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [conversations, setConversations] = useState<Conversation[]>(null);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [recipient, setReceipient] = useState(null);
  const [openListboxDialog, setOpenListboxDialog] = useState(false);
  const [isEditingGroupName, setIsEditingGroupName] = useState(false);
  const [editedGroupName, setEditedGroupName] = useState('');
  const [editingConversationId, setEditingConversationId] = useState(null);
  const [isUpdatingGroupName, setIsUpdatingGroupName] = useState(false);
  const [openAddNewMemberModal, setOpenAddNewMemberModal] = useState(false);
  const [isUserRemoved, setIsUserRemoved] = useState(false);
  const [didUserDeleteConversation, setDidUserDeleteConversation] =
    useState(false);
  const [oldMessages, setOldMessages] = useState([]);
  const [
    openDeleteConversationConfirmationModal,
    setOpenDeleteConversationConfirmationModal,
  ] = useState(false);
  const [isDeletingConversation, setIsDeletingConversation] = useState(false);

  const httpClient = useHttpClient();
  const location = useLocation();

  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;

  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get('id');
  const conversationId = queryParams.get('conversation');
  const inputRef = useRef(null);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setIsSubmitting(true);
    try {
      const { data } = await httpClient.post(`/api/messages/`, {
        text: newMessage,
        recipientid: recipient?.id,
        id: selectedConversation,
      });

      const timestamp = new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
      setNewMessage('');

      if (memberId) {
        // Reorder conversations after sending a message
        setConversations((prevConversations) => {
          if (!prevConversations) return prevConversations;

          const currentConversation = prevConversations.find(
            (conv) => conv.id === selectedConversation,
          );
          if (!currentConversation) return prevConversations;

          const otherConversations = prevConversations.filter(
            (conv) => conv.id !== selectedConversation,
          );

          return [currentConversation, ...otherConversations];
        });
      }
    } catch (error) {
      notifications.show({
        title: 'Unable to send message',
        message: 'Error:' + error.message,
        color: 'red',
        position: 'bottom-center',
      });
    }
    setIsSubmitting(false);
  };

  const currentConversation = conversations?.find(
    (conv) => conv.id === selectedConversation,
  );

  const checkUserRemovalStatus = useCallback(
    async (conversationId) => {
      try {
        const { data } = await httpClient.get(
          `/api/messages/conversations/${conversationId}/is-removed`,
        );
        setIsUserRemoved(data.isRemoved);
        setDidUserDeleteConversation(data.didUserDeleteConversation);
      } catch (error) {
        console.error('Error checking user removal status:', error);
      }
    },
    [httpClient],
  );

  const fetchOldMessagesBeforeRemoval = async (conversationId: number) => {
    try {
      const response = await httpClient.get(
        `/api/messages/conversations/${conversationId}/messages-before-removal`,
      );
      setOldMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages before removal:', error);
    }
  };

  useEffect(() => {
    if (isUserRemoved && !didUserDeleteConversation) {
      fetchOldMessagesBeforeRemoval(currentConversation?.id);
    }
  }, [isUserRemoved, currentConversation, didUserDeleteConversation]);

  useEffect(() => {
    if (selectedConversation) {
      checkUserRemovalStatus(selectedConversation);
    }
  }, [selectedConversation, checkUserRemovalStatus]);

  const loadConversations = useCallback(async () => {
    try {
      if (!conversations) {
        const { data } = await httpClient.get(`/api/messages/conversations`);

        setConversations(data);
        if (!memberId && data[0]?.id) {
          setSelectedConversation(data[0]?.id);
        }
      }
    } catch (error) {
      console.error('Error loading conversations', error);
    }
    if (!memberId) {
      setIsConversationLoading(false);
    }
  }, [conversations, httpClient, memberId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [isSubmitting]);

  useEffect(() => {
    (async () => {
      if (conversations) {
        const currentConv: any = conversations?.filter(
          (c) => c.id === selectedConversation,
        )[0];
        if (currentConv?.lastMessageId && selectedConversation) {
          await httpClient.patch(
            `/api/messages/read/${selectedConversation}/${currentConv?.lastMessageId}`,
          );

          setTimeout(() => {
            setConversations((conv) => {
              const currentConv: any = conv.filter(
                (c) => c.id === selectedConversation,
              )[0];
              const idx = conv.indexOf(currentConv);
              conv[idx].isNew = false;
              return conv;
            });
          });
        }
      }
    })();
  }, [selectedConversation, conversations, httpClient]);

  useEffect(() => {}, [selectedConversation]);

  const [hasMoreMessages, setHasMoreMessages] = useState(true); // Assume there are more messages initially
  const [isAtBottom, setIsAtBottom] = useState(true);
  const scrollRef = useRef(null);

  useEffect(() => {
    // setIsConversationLoading(true);
    loadConversations();
    inputRef.current?.focus();
  }, [loadConversations]);

  const newMessageCb = useCallback(async () => {
    if (memberId) {
      setIsConversationLoading(true);
      const { data } = await httpClient.get(
        `/api/members/community/members/${memberId}`,
      );

      setReceipient(data);
      setConversations((prevConversations) => {
        const existingConv = prevConversations?.filter(
          (c) => c.memberid === Number(memberId),
        )[0];

        if (existingConv) {
          setSelectedConversation(existingConv.id);
          return prevConversations;
        } else {
          setNewMessage(``);
          return [
            ...(prevConversations?.filter((conv) => conv.id === null).length ===
            0
              ? [
                  {
                    id: null,
                    memberid: Number(memberId),
                    name: data?.name,
                    messages: [],
                  } as Conversation,
                ]
              : []),
            ...(prevConversations || []),
          ];
        }
      });
      setIsConversationLoading(false);
    }
  }, [httpClient, memberId]);

  useEffect(() => {
    newMessageCb();
  }, [newMessageCb]);

  // Scroll to bottom on initial render or new messages
  function scrollToBottom() {
    if (isAtBottom && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        // behavior: 'smooth',
      });
    }
  }
  useEffect(scrollToBottom, [isAtBottom, conversations]);
  useEffect(() => {
    queryParams.delete('id');
    scrollToBottom();
  }, [isAtBottom, selectedConversation]);

  const updateMessages = async (data, readOldMessage = false) => {
    const conversationId = data.conversationId;

    setIsMessagesLoading(true);
    try {
      const currentConv = conversations?.find((c) => c.id === conversationId);

      console.log(
        currentConv,
        conversations,
        conversationId,
        typeof conversationId,
      );

      if (currentConv) {
        console.log('currentConv');
        let param = readOldMessage
          ? `f=${currentConv.firstMessageId}`
          : `l=${currentConv.lastMessageId}`;

        const { data: conversation_ } = await httpClient.get(
          `/api/messages/conversations/${conversationId}?${param}`,
        );
        const messageData = conversation_.messages;
        setHasMoreMessages(messageData.length > 0);

        setConversations((conv) => {
          const currentConv = conv.find((c) => c.id === conversationId);
          const idx = conv.indexOf(currentConv);

          if (readOldMessage) {
            const minValue = Math.min(...messageData.map((item) => item.id));
            conv[idx].firstMessageId = minValue === Infinity ? 0 : minValue;
            // Ensure no duplicate messages
            const uniqueMessages = messageData.filter(
              (msg) => !conv[idx].messages.some((m) => m.id === msg.id),
            );
            conv[idx].messages = [...uniqueMessages, ...conv[idx].messages];
          } else {
            const maxValue = Math.max(...messageData.map((item) => item.id));
            conv[idx].lastMessageId = maxValue === Infinity ? 0 : maxValue;
            // Ensure no duplicate messages
            const uniqueMessages = messageData.filter(
              (msg) => !conv[idx].messages.some((m) => m.id === msg.id),
            );
            conv[idx].messages = [...conv[idx].messages, ...uniqueMessages];
          }

          // Ensure group name and isGroup are updated
          conv[idx].isNew = true;
          conv[idx].isGroup = conversation_.isGroup;
          conv[idx].name = conversation_.name;
          conv[idx].users = conversation_.users; // Ensure users are updated

          return [...conv];
        });
        // scrollRef.current.scrollTop = 0;
      } else {
        console.log('!currentConv');
        const { data: conversation_ } = await httpClient.get(
          `/api/messages/conversations/${conversationId}?ts=` +
            new Date().getTime(),
        );
        const messageData = conversation_.messages;

        setConversations((conv) => {
          const newConv: Conversation = {
            id: conversation_.id,
            name: conversation_.name,
            isGroup: conversation_.isGroup,
            firstMessageId: Math.min(...messageData.map((item) => item.id)),
            lastMessageId: Math.max(...messageData.map((item) => item.id)),
            memberid: conversation_.memberid,
            messages: messageData,
            key: conversation_.key,
            isNew: true,
            users: conversation_.users, // Include users
          };

          const convFiltered = conv.filter(
            (conv_) => conv_.id !== conversationId,
          );
          return [newConv, ...convFiltered];
        });
      }
      if (!selectedConversation) {
        setSelectedConversation(conversationId);
      }
    } finally {
      setIsMessagesLoading(false);
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 500);
    }
  };

  // Load more messages when scrolled to the top
  const handleScroll = async (e) => {
    const { scrollTop } = e.target;
    if (scrollTop === 0 && !isConversationLoading && hasMoreMessages) {
      // Trigger load more messages when user scrolls to the top
      // setPages((pages_) => {
      //   return {
      //     ...pages_,
      //     [selectedConversation]: (pages_[selectedConversation] || 0) + 1,
      //   };
      // });
      await updateMessages({ conversationId: selectedConversation }, true);
    }

    // Check if we're at the bottom
    const isAtBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setIsAtBottom(isAtBottom);
  };

  useEffect(() => {
    try {
      const socket = io(process.env.REACT_APP_WS_URL);

      socket.on('connect', () => {
        console.log('Socket.IO connection established');
        socket.emit('register', username);
      });

      socket.on('registered', (response) => {
        console.log('Registration status:', response.status);
      });

      socket.on('NEW_MESSAGE', async (data) => {
        console.log(' Socket received message:', data);
        try {
          await updateMessages(data);
          // After updating messages, reorder conversations
          // setConversations((prevConversations) => {
          //   if (!prevConversations) return prevConversations;

          //   const updatedConversation = prevConversations.find(
          //     (conv) => conv.id === data.conversationId,
          //   );
          //   if (!updatedConversation) return prevConversations;

          //   const otherConversations = prevConversations.filter(
          //     (conv) => conv.id !== data.conversationId,
          //   );

          //   return [updatedConversation, ...otherConversations];
          // });
        } catch (e) {
          console.error('Error handling new message:', e);
        }
      });

      socket.on('disconnect', (reason) => {
        console.log('Socket.IO connection closed:', reason);
      });

      socket.on('connect_error', (error) => {
        console.error('Socket.IO connection error:', error);
      });

      return () => {
        socket.disconnect();
      };
    } catch (e) {
      console.error('Could not initiate Socket.IO connection:', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditGroupName = async (conversationId) => {
    setIsUpdatingGroupName(true);
    try {
      const { data } = await httpClient.patch(
        `/api/messages/conversations/edit-group-name/${conversationId}`,
        {
          name: editedGroupName,
        },
      );
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.id === conversationId ? { ...conv, name: data.name } : conv,
        ),
      );
    } catch (error) {
      console.error('Error updating group name:', error);
    } finally {
      setIsEditingGroupName(false);
      setEditingConversationId(null);
      setIsUpdatingGroupName(false);
    }
  };

  const handleDeleteConversation = async (conversationId) => {
    setIsDeletingConversation(true);
    try {
      const response = await httpClient.patch(
        `/api/messages/conversations/${conversationId}/soft-delete-conversation`,
      );

      if (response.status === 200) {
        setConversations((prevConversations) => {
          const updatedConversations = prevConversations.filter(
            (conv) => conv.id !== conversationId,
          );
          setSelectedConversation(updatedConversations[0]?.id);
          return updatedConversations;
        });
        setOpenDeleteConversationConfirmationModal(false);
        notifications.show({
          title: 'Conversation deleted',
          message: 'The conversation has been deleted',
          color: 'green',
        });
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
      notifications.show({
        title: 'Error',
        message: 'An error occurred while deleting the conversation',
        color: 'red',
      });
    } finally {
      setIsDeletingConversation(false);
      setOpenDeleteConversationConfirmationModal(false);
    }
  };

  // Effect to handle URL changes and conversation selection
  useEffect(() => {
    if (conversationId && conversations) {
      const conversation = conversations.find(
        (conv) => conv.id === Number(conversationId),
      );
      if (conversation) {
        setSelectedConversation(Number(conversationId));
      }
    } else {
      setSelectedConversation(null);
    }
  }, [conversationId]); // This effect runs when URL or conversations change

  // Effect to handle marking messages as read and updating UI
  useEffect(() => {
    (async () => {
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
            // behavior: 'smooth',
          });
        }
      });

      if (conversations) {
        const currentConv: any = conversations?.filter(
          (c) => c.id === selectedConversation,
        )[0];
        if (currentConv?.lastMessageId && selectedConversation) {
          await httpClient.patch(
            `/api/messages/read/${selectedConversation}/${currentConv?.lastMessageId}`,
          );

          setTimeout(() => {
            setConversations((conv) => {
              const currentConv: any = conv.filter(
                (c) => c.id === selectedConversation,
              )[0];
              const idx = conv.indexOf(currentConv);
              conv[idx].isNew = false;
              return [...conv]; // Create a new array to trigger re-render
            });
          });
        }
      }
    })();
  }, [selectedConversation, httpClient]);

  // Effect to handle component mount/unmount
  useEffect(() => {
    return () => {
      setSelectedConversation(null);
    };
  }, []);

  // Effect to load conversations
  useEffect(() => {
    if (!isConversationLoading) {
      loadConversations();
    }
  }, [loadConversations, isConversationLoading]);

  return (
    <>
      <Grid>
        <Grid.Col
          span={4}
          style={{
            height: 'calc(100vh - 100px)',
          }}
        >
          {isConversationLoading ? (
            <Loader style={{ position: 'absolute' }} />
          ) : null}
          <>
            <ScrollArea scrollbars="y" h={'calc(100% - 50px)'} w={'100%'}>
              {(conversations || [])?.map((conversation) => (
                <Box
                  key={`conversation-${conversation.id}`}
                  p="sm"
                  maw={'100%'}
                  onClick={() => setSelectedConversation(conversation.id)}
                  style={{
                    cursor: 'pointer',
                    color:
                      conversation.id === selectedConversation
                        ? computedColorScheme === 'light'
                          ? 'blue'
                          : 'yellow'
                        : 'unset',
                    backgroundColor:
                      conversation.id === selectedConversation
                        ? 'rgba(220,220,220,0.5)'
                        : 'transparent',
                    borderRadius: '4px',
                    borderBottom: '1px solid #CCC',
                    display: 'flex',
                    alignItems: 'center', // Align avatar and text vertically
                    justifyContent: 'space-between', // Distribute space between elements
                  }}
                >
                  <RyzeAvatar
                    memberId={conversation.memberid}
                    style={{ marginRight: '10px' }}
                  />
                  <Group justify="space-between" style={{ flex: 1 }}>
                    <Flex
                      direction="row"
                      align={'center'}
                      style={{
                        flex: 1,
                      }}
                    >
                      <Box flex={1}>
                        <Text
                          style={{
                            fontWeight: conversation.isNew ? 500 : 200,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '30ch',
                          }}
                        >
                          {conversation.name}
                        </Text>
                        <Text
                          style={{
                            fontSize: '0.8em',
                            color: '#AAA',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxHeight: '20px',
                            width: '240px',
                            textWrap: 'nowrap',

                            float: 'left',
                          }}
                        >
                          {isUserRemoved &&
                          currentConversation?.id === conversation.id
                            ? oldMessages?.[oldMessages.length - 1]?.timestamp
                            : conversation.messages?.[
                                conversation.messages?.length - 1
                              ]?.timestamp}
                          {': '}
                          {parseMessage(
                            isUserRemoved &&
                              currentConversation?.id === conversation.id
                              ? oldMessages?.[oldMessages.length - 1]?.text
                              : conversation.messages?.[
                                  conversation.messages?.length - 1
                                ]?.text,
                            username,
                          )}
                        </Text>
                      </Box>
                      {conversation.isGroup &&
                        currentConversation?.id === conversation.id && (
                          <Flex direction="row" gap={'xs'}>
                            {!isUserRemoved ? (
                              <>
                                <ActionIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setIsEditingGroupName(true);
                                    setEditedGroupName(conversation.name);
                                    setEditingConversationId(conversation.id);
                                  }}
                                >
                                  <IconEdit size={16} />
                                </ActionIcon>
                                <ActionIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenAddNewMemberModal(true);
                                    setSelectedConversation(conversation.id);
                                  }}
                                >
                                  <IconDots size={16} />
                                </ActionIcon>
                              </>
                            ) : (
                              <ActionIcon
                                color="red"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenDeleteConversationConfirmationModal(
                                    true,
                                  );
                                  setSelectedConversation(conversation.id);
                                }}
                              >
                                <IconTrash size={16} />
                              </ActionIcon>
                            )}
                          </Flex>
                        )}
                    </Flex>
                  </Group>
                </Box>
              ))}
              <ConnectionsListbox
                isModalOpen={openListboxDialog}
                onClose={() => setOpenListboxDialog(false)}
              />
              <EditGroupNameModal
                isOpen={isEditingGroupName}
                onClose={() => setIsEditingGroupName(false)}
                editedGroupName={editedGroupName}
                setEditedGroupName={setEditedGroupName}
                isUpdating={isUpdatingGroupName}
                handleEditGroupName={handleEditGroupName}
                editingConversationId={editingConversationId}
              />
              <OptionsModal
                opened={openAddNewMemberModal}
                onClose={() => setOpenAddNewMemberModal(false)}
                selectedConversation={selectedConversation}
                updateMessages={updateMessages}
              />
              <DeleteConfirmationModal
                title="Delete Conversation"
                description="Are you sure you want to delete this conversation?"
                opacity={0.5}
                opened={openDeleteConversationConfirmationModal}
                onClose={() =>
                  setOpenDeleteConversationConfirmationModal(false)
                }
                onSubmit={() => handleDeleteConversation(selectedConversation)}
                isDeleting={isDeletingConversation}
              />
            </ScrollArea>
            <Button
              mt={20}
              onClick={() => {
                setOpenListboxDialog(true);
              }}
              style={{
                marginBottom: '10px',
                width: '100%',
              }}
            >
              Create new group
            </Button>
          </>
        </Grid.Col>
        <Grid.Col span={8}>
          <Group align="stretch" style={{ height: '100%' }}>
            <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <DragAndDropUpload
                conversation={currentConversation}
                setIsSubmitting={setIsSubmitting}
              >
                {conversations?.map((currentConversation_) => (
                  <Box
                    ref={
                      currentConversation_?.id === selectedConversation
                        ? scrollRef
                        : null
                    }
                    onScroll={handleScroll}
                    style={{
                      flex: 1,
                      padding: '16px',
                      height: 'calc(100vh - 216px)',
                      maxHeight: 'calc(100vh - 216px)',
                      overflowY: 'auto',
                      position: 'relative',
                      display:
                        currentConversation_?.id === selectedConversation
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {isMessagesLoading &&
                      currentConversation_?.messages.length > 0 && (
                        <Group justify="center" mb="xl">
                          <Loader size="sm" />
                        </Group>
                      )}

                    {!currentConversation_?.messages.length && memberId && (
                      <Text style={{ fontStyle: 'italic', color: '#999' }}>
                        New convesation with{' '}
                        {recipient?.preferredname || recipient?.firstname}
                      </Text>
                    )}

                    {isUserRemoved &&
                      oldMessages.length > 0 &&
                      oldMessages.map((message, index) => {
                        const isGroupChat = currentConversation_?.isGroup;
                        const isOwnMessage = message.sender === 'Me';

                        // Determine the correct memberId for the avatar
                        let avatarMemberId = null;
                        if (isOwnMessage) {
                          avatarMemberId = privileges[0].serviceid;
                        } else if (isGroupChat) {
                          avatarMemberId = message.senderId;
                        } else {
                          avatarMemberId = currentConversation_?.memberid;
                        }

                        return (
                          <Box
                            key={`message-${currentConversation_?.id}-${message.id}-${index}`}
                            style={{
                              display: 'flex',
                              flexDirection: isOwnMessage
                                ? 'row-reverse'
                                : 'row',
                              alignItems: 'center',
                              marginBottom: '16px',
                              gap: '8px',
                            }}
                          >
                            <RyzeAvatar
                              key={`${currentConversation_?.id}-${message.id}-${avatarMemberId}`}
                              style={{
                                alignSelf: 'center',
                                justifySelf: 'center',
                              }}
                              memberId={avatarMemberId}
                              size="md"
                            />

                            <Box>
                              <Text
                                size="xs"
                                mb={4}
                                style={{
                                  color: '#666',
                                  textAlign:
                                    message.sender === 'Me' ? 'right' : 'left',
                                }}
                              >
                                {message.sender === 'Me'
                                  ? 'You'
                                  : message.sender || 'Unknown User'}
                              </Text>

                              <Box
                                style={{
                                  maxWidth: '424px',
                                  padding: '12px',
                                  borderRadius: '16px',
                                  backgroundColor:
                                    message.sender === 'Me'
                                      ? '#007BFF'
                                      : '#e0e0e0',
                                  color:
                                    message.sender === 'Me' ? 'white' : 'black',
                                  textAlign: 'left',
                                  wordWrap: 'break-word',
                                }}
                              >
                                <Text size="sm">
                                  <RyzeMessageAttachment
                                    url={message.text}
                                    id={message.id}
                                    conversation={currentConversation_}
                                    scrollRef={scrollRef}
                                  />
                                </Text>
                                <Text
                                  size="xs"
                                  style={{
                                    marginTop: '4px',
                                    opacity: 0.7,
                                    textAlign: 'right',
                                  }}
                                >
                                  {/* {message.id + ':::'} */}
                                  {message.timestamp}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}

                    {!isUserRemoved &&
                      currentConversation_?.messages.map((message, index) => {
                        const isGroupChat = currentConversation_.isGroup;
                        const isOwnMessage = message.sender === 'Me';

                        // Determine the correct memberId for the avatar
                        let avatarMemberId = null;
                        if (isOwnMessage) {
                          avatarMemberId = privileges[0].serviceid;
                        } else if (isGroupChat) {
                          avatarMemberId = message.senderId;
                        } else {
                          avatarMemberId = currentConversation_.memberid;
                        }

                        return (
                          <Box
                            key={`message-${currentConversation_.id}-${message.id}-${index}`}
                            style={{
                              display: 'flex',
                              flexDirection: isOwnMessage
                                ? 'row-reverse'
                                : 'row',
                              alignItems: 'center',
                              marginBottom: '16px',
                              gap: '8px',
                            }}
                          >
                            <RyzeAvatar
                              key={`${currentConversation_.id}-${message.id}-${avatarMemberId}`}
                              style={{
                                alignSelf: 'center',
                                justifySelf: 'center',
                              }}
                              memberId={avatarMemberId}
                              size="md"
                            />

                            <Box>
                              <Text
                                size="xs"
                                mb={4}
                                style={{
                                  color: '#666',
                                  textAlign:
                                    message.sender === 'Me' ? 'right' : 'left',
                                }}
                              >
                                {message.sender === 'Me'
                                  ? 'You'
                                  : message.sender || 'Unknown User'}
                              </Text>

                              <Box
                                style={{
                                  maxWidth: '424px',
                                  padding: '12px',
                                  borderRadius: '16px',
                                  backgroundColor:
                                    message.sender === 'Me'
                                      ? '#007BFF'
                                      : '#e0e0e0',
                                  color:
                                    message.sender === 'Me' ? 'white' : 'black',
                                  textAlign: 'left',
                                  wordWrap: 'break-word',
                                }}
                              >
                                <Text size="sm">
                                  <RyzeMessageAttachment
                                    url={message.text}
                                    id={message.id}
                                    conversation={currentConversation_}
                                    scrollRef={scrollRef}
                                  />
                                </Text>
                                <Text
                                  size="xs"
                                  style={{
                                    marginTop: '4px',
                                    opacity: 0.7,
                                    textAlign: 'right',
                                  }}
                                >
                                  {/* {message.id + ':::'} */}
                                  {message.timestamp}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    {isSubmitting ? (
                      <Box>
                        <Loader size="xs" mr="xs" />
                        <Text c="blue" size="xs">
                          Please wait...
                        </Text>
                      </Box>
                    ) : null}
                  </Box>
                ))}
              </DragAndDropUpload>

              {/* Message Input */}
              <Box p="md" style={{ borderTop: '1px solid #e0e0e0' }}>
                {!isUserRemoved ? (
                  <Group>
                    <TextInput
                      ref={inputRef}
                      placeholder="Type a message..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      style={{ flex: 1 }}
                      maxLength={200}
                      disabled={!conversations?.length}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          handleSendMessage();
                          e.preventDefault();
                        }
                      }}
                    />
                    <Button
                      onClick={() => {
                        handleSendMessage();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSendMessage();
                        }
                      }}
                      disabled={!newMessage.trim() || isSubmitting}
                    >
                      {isSubmitting ? (
                        <Loader size="xs" mr="xs" color="#CCC" />
                      ) : (
                        <IconSend size={16} style={{ marginRight: '5' }} />
                      )}
                      Send
                    </Button>
                  </Group>
                ) : (
                  <Group justify="center">
                    <Text style={{ fontStyle: 'italic', color: '#999' }}>
                      You have been removed from this conversation
                    </Text>
                  </Group>
                )}
              </Box>
            </Box>
          </Group>
        </Grid.Col>
      </Grid>
      {/* <pre>{JSON.stringify(conversations, null, 2)}</pre> */}
    </>
  );
};

export default MessengerPage;
