import React, { useState, useEffect } from 'react';
import { Tabs } from '@mantine/core';
import PersonalInformationTab from './PersonalInformationTab';
import FamilyMemberManagementTab, {
  familyMgmtValidation,
} from './FamilyManagementTab';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import { notifications } from '@mantine/notifications';
import PlanSelectionTab from './PlanSelectionTab';
import PaymentInformationTab from './PaymentInformationTab';
import CompleteRegistrationModal from './CompleteMemberRegistration';
import { Container } from '@mantine/core';
import { OrganizationProvider } from './OrganizationContext';
import { InsuranceMemberProvider } from './InsuranceMemberContext';
import { useLocation } from 'react-router-dom';
import { subMenuList } from '../menu.config';
import {
  useStepper,
  NextStepButton,
  PrevStepButton,
  CompleteStepButton,
  ButtonContainer,
  IndependentPhysRegStepper,
  StepperProvider,
} from './RegistrationSteppers';
import { ModalsProvider } from '@mantine/modals';

import PrioritiesTab from './PrioritiesTab';
import ThankYouModal from './ThankYouModal';

const InsuranceMemberPortal: React.FC = () => {
  const location = useLocation();
  const currentTab = location.hash.substring(1) || 'personal-info';
  const { enabled, markStepAsDone, subMenu } = useStepper();

  const { refreshMemberData, member } = useInsuranceMemberContext();
  const [modalOpened, setModalOpened] = useState(false);
  const [isFamilyFieldsComplete, setIsFamilyFieldsComplete] = useState(false); //temp true until implement check
  const [isPrioritiesComplete, setIsPrioritiesComplete] = useState(true);
  const [isPlanSelectionComplete, setIsPlanSelectionComplete] = useState(false);
  const [isPaymentInfoComplete, setIsPaymentInfoComplete] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(currentTab);
  const [thankYouModalOpened, setThankYouModalOpened] = useState(false);

  const currIndex = subMenu.findIndex((item) => item?.key === currentTab);

  const validateForm = (infoComplete) => {
    if (infoComplete) {
      markStepAsDone(activeTab);
      return true;
    } else {
      markStepAsDone(activeTab, false);
      return false;
    }
  };

  /**
   * Checks if all required personal information fields are complete.
   *
   * @returns `true` if all fields are complete, otherwise `false`.
   */
  const checkFieldsComplete = async () => {
    const requiredPersonalInfoFields = [
      'firstname',
      'lastname',
      'birthdate',
      'email',
      'street1',
      'city',
      'state',
      'zip',
      'county',
    ];
    const incompleteFields: string[] = [];
    const completeFields: string[] = [];

    const isPersonalInfoComplete = requiredPersonalInfoFields.every((field) => {
      const isFieldComplete =
        member &&
        member[field as keyof typeof member] &&
        member[field as keyof typeof member] !== '';
      if (isFieldComplete) {
        completeFields.push(field);
      } else {
        incompleteFields.push(field);
      }
      return isFieldComplete;
    });

    // Log both the complete and incomplete fields for debugging purposes
    console.log('Complete Fields:', completeFields);
    console.log('Incomplete Fields:', incompleteFields);

    if (activeTab === 'personal-info') {
      return validateForm(isPersonalInfoComplete);
    }
    if (activeTab === 'priorities') {
      return validateForm(isPrioritiesComplete);
    }
    if (activeTab === 'plan-selection') {
      return validateForm(isPlanSelectionComplete);
    }
    if (activeTab === 'payment-information') {
      return validateForm(isPaymentInfoComplete);
    }
    if (activeTab === 'family-member-management') {
      return validateForm(isFamilyFieldsComplete);
    }
    return true;
  };

  const handleOpenModal = () => {
    if (validateForm(isPaymentInfoComplete)) {
      setModalOpened(true);
    } else {
      notifications.show({
        title: 'Incomplete Information',
        message: 'Please complete your payment information before proceeding.',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const validationCallback = async () => {
    await refreshMemberData();

    if (activeTab === 'family-member-management') {
      const isFamilyConfirmed = await familyMgmtValidation();
      if (isFamilyConfirmed) {
        markStepAsDone(activeTab);
      } else {
        markStepAsDone(activeTab, false);
      }
      return isFamilyConfirmed;
    }

    return await checkFieldsComplete();
  };

  useEffect(() => {
    setActiveTab(currentTab);
    if (member) {
      checkFieldsComplete();
    }
  }, [currentTab, member, isFamilyFieldsComplete]);

  return (
    <>
      <ButtonContainer>
        <PrevStepButton enabled={enabled} />
        <IndependentPhysRegStepper />

        {/* {currIndex === subMenu.length - 2 && ( */}
        {currIndex === subMenu.length - 1 && (
          <CompleteStepButton
            enabled={true}
            onClick={() => {
              setThankYouModalOpened(true);
            }}
          />
        )}

        {/* {currIndex !== subMenu.length - 2 && ( */}
        {currIndex !== subMenu.length - 1 && (
          <ModalsProvider>
            <NextStepButton
              enabled={enabled}
              // enabled={enabled && activeTab !== 'plan-selection'}
              validationCallback={validationCallback}
            />
          </ModalsProvider>
        )}
      </ButtonContainer>
      <ThankYouModal
        opened={thankYouModalOpened}
        onClose={() => setThankYouModalOpened(false)}
        title="Thank you for selecting your plan"
        description="Once the Ryze plans are live, our team will email you to complete your sign up."
      />
      <CompleteRegistrationModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      />
      <Tabs defaultValue="personal-info" keepMounted={false} value={activeTab}>
        <Tabs.Panel value="personal-info">
          {member && (
            <PersonalInformationTab
              onFormSubmit={() => {
                refreshMemberData();
              }}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="family-member-management">
          {member && (
            <FamilyMemberManagementTab
              onFieldsCompleteChange={setIsFamilyFieldsComplete}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="priorities">
          {member && (
            <PrioritiesTab onFieldsCompleteChange={setIsPrioritiesComplete} />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="plan-selection">
          {member && (
            <PlanSelectionTab
              onFieldsCompleteChange={setIsPlanSelectionComplete}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="payment-information">
          <PaymentInformationTab
            isMember={true}
            onFieldsCompleteChange={setIsPaymentInfoComplete}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const InsuranceMemberPortalWrapper: React.FC = () => {
  const subMenu = subMenuList.independentPhysician.menu;

  return (
    <OrganizationProvider>
      <InsuranceMemberProvider>
        <StepperProvider totalSteps={5} subMenu={subMenu}>
          <Container size="xl" style={{ marginTop: '2rem' }}>
            <InsuranceMemberPortal />
          </Container>
        </StepperProvider>
      </InsuranceMemberProvider>
    </OrganizationProvider>
  );
};

export default InsuranceMemberPortalWrapper;
