/**
 * Encrypt an image file using AES-GCM encryption.
 * @param {File} file - The image file to encrypt.
 * @param {string} password - A password for encryption.
 * @returns {Promise<{ encryptedData: ArrayBuffer, iv: Uint8Array }>} - Encrypted data and initialization vector.
 */
export async function encryptImage(file, password) {
  const fileData = await file.arrayBuffer(); // Read file data
  const passwordKey = await deriveKey(password);

  // Generate a random Initialization Vector (IV)
  const iv = crypto.getRandomValues(new Uint8Array(12)); // 96 bits IV for AES-GCM

  // Encrypt the file data
  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    passwordKey,
    fileData,
  );

  return { encryptedData, iv };
}

/**
 * Decrypt encrypted image data using AES-GCM decryption.
 * @param {ArrayBuffer} encryptedData - The encrypted data.
 * @param {Uint8Array} iv - The initialization vector (IV) used during encryption.
 * @param {string} password - The password used during encryption.
 * @returns {Promise<Blob>} - The decrypted image as a Blob.
 */
export async function decryptImage(encryptedData, iv, password) {
  const passwordKey = await deriveKey(password);

  // Decrypt the file data
  const decryptedData = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    passwordKey,
    encryptedData,
  );

  // Return the decrypted data as a Blob (image format)
  return new Blob([decryptedData]);
}

/**
 * Helper function to derive a cryptographic key from a password.
 * @param {string} password - The password to derive the key from.
 * @returns {Promise<CryptoKey>} - A derived AES-GCM key.
 */
async function deriveKey(password) {
  // Encode the password as UTF-8
  const passwordBuffer = new TextEncoder().encode(password);

  // Create a cryptographic key from the password
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    passwordBuffer,
    { name: 'PBKDF2' },
    false,
    ['deriveKey'],
  );

  // Derive a key using PBKDF2 with a fixed salt and iteration count
  const salt = new Uint8Array([1, 2, 3, 4, 5, 6, 7, 8]); // Example salt
  return crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt,
      iterations: 100000,
      hash: 'SHA-256',
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt', 'decrypt'],
  );
}
