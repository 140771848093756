import React, { useState, useRef, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import useHttpClient from './hooks/useHttpClient';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export function DownloadAndRenderImage({ url }) {
  const [imageData, setImageData] = useState<string | null>(null);
  const httpClient = useHttpClient();

  React.useEffect(() => {
    httpClient
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setImageData(url);
      })
      .catch((error) => console.error('Error fetching image:', error));
  }, [url]);

  if (!imageData) return <div>Loading...</div>;

  return (
    <img src={imageData} alt="Downloaded Content" style={{ width: '100%' }} />
  );
}

export function DownloadAndRenderPDF({ url }) {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [fileData, setFileData] = useState<string | null>(null);
  const httpClient = useHttpClient();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (url) {
      httpClient
        .get(url, { responseType: 'arraybuffer' })
        .then((response) => {
          const file = new Blob([response.data], { type: 'application/pdf' });
          console.log(response.data);

          let base64String;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            base64String = reader.result;
            setFileData(base64String.substr(base64String.indexOf(',') + 1));
          };
        })
        .catch((error) => console.error('Error fetching file:', error));
    }
  }, [url]);

  function handlePreviousPage() {
    if (pageNumber > 1) setPageNumber((prev) => prev - 1);
  }

  function handleNextPage() {
    if (pageNumber < numPages) setPageNumber((prev) => prev + 1);
  }

  return (
    <div
      style={{
        border: '1px solid #CCC',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Document
        file={`data:application/pdf;base64,${fileData}`}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="canvas"
      >
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
          gap: 10,
          marginTop: 10,
        }}
      >
        <button onClick={handlePreviousPage} disabled={pageNumber <= 1}>
          &lt;
        </button>
        <span>{`Page ${pageNumber} of ${numPages}`}</span>
        <button onClick={handleNextPage} disabled={pageNumber >= numPages}>
          &gt;
        </button>
      </div>
    </div>
  );
}
