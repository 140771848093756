import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Textarea,
  Group,
  Collapse,
  Paper,
  Grid,
  MultiSelect,
  Input,
  Autocomplete,
  Checkbox,
  Table,
  Title,
  Text,
  Tooltip,
  Radio,
  NumberInput,
  Accordion,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { LANGUAGES, Member, STATES_ARRAY } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';
import PracticeLocationEmployeeModal from './InsurancePortal/PracticeLocationEmployeeModal';
import { IconX, IconPencil, IconInfoCircle } from '@tabler/icons-react';
import { addSeconds } from 'date-fns';
import { modals } from '@mantine/modals';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import PracticeLimitations from './PracticeLocationLimitationsForm';
import PracticeLocationAffliationForm from './InsurancePortal/PracticeLocationAffliationForm';
import PracticeLocationAccessibilityForm from './InsurancePortal/PracticeLocationAccessibilityForm';
import PatientSection from './PracticeLocationPatientForm';

const { Thead, Tbody, Th, Tr, Td } = Table;

interface FormProps {
  memberId: number;
}

const PracticeForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm({});

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'practices',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();
  const [practicesList, setPracticesList] = useState<any[]>([]);
  const [practiceLocations, setPracticeLocations] = useState<any[]>([]);
  const [practiceSearchTerms, setPracticeSearchTerms] = useState<string[]>(
    fields.map(() => ''),
  );
  const [locationSearchTerms, setLocationSearchTerms] = useState<string[]>(
    fields.map(() => ''),
  );
  const httpClient = useHttpClient();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formType, setFormType] = useState<'colleague' | 'office_personnel'>();
  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [selectedMemberId, setSelectedMemberId] = useState<number>();

  const [autocompleteData, setAutocompleteData] = useState<
    Array<{ value: string; label: string; searchFields: string }>
  >([]);

  const [selectedPractice, setSelectedPractice] = useState<any>();
  const [ownedPractices, setOwnedPractices] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPracticeLocationAccordion, setOpenPracticeLocationAccordion] =
    useState(true);

  const handlePracticeSearchChange = (value: string, index: number) => {
    setPracticeSearchTerms((prev) => {
      const newSearchTerms = [...prev];
      newSearchTerms[index] = value;
      return newSearchTerms;
    });
  };

  const handleLocationSearchChange = (value: string, index: number) => {
    setLocationSearchTerms((prev) => {
      const newSearchTerms = [...prev];
      newSearchTerms[index] = value;
      return newSearchTerms;
    });
  };

  const fetchPracticeLocations = async (selectedPractice: any) => {
    const response = await httpClient.get(
      `/api/practices/${memberId}/locations`,
      {
        params: {
          id: selectedPractice.id,
          legalbusinessname: selectedPractice.legalbusinessname,
          taxid: selectedPractice.taxid,
          typeoftaxid: selectedPractice.typeoftaxid,
          organizationnpi: selectedPractice.organizationnpi,
        },
      },
    );

    const fetchedLocations = response.data;

    // Use a Set to track unique locations
    const uniqueLocations = new Set();
    const formattedLocations = fetchedLocations
      .map((location: any) => ({
        value: `${location.street1}, ${location.city}`,
        label: `${location.street1}, ${location.city}`,
        searchFields: `${location.street1} ${location.city} ${location.state} ${location.zip} ${location.phone} ${location.country} ${location.county} ${location.practicelocationemailaddress} ${location.practicelocationwebsite} ${location.appointmentschedulingwebsite} ${location.appointmentphonenumber} ${location.faxnumber} ${location.accessibility} ${location.languages.join(' ')} ${location.managerfname} ${location.managerlname} ${location.managerphone} ${location.manageremail}`,
        street1: location.street1,
        city: location.city,
        state: location.state,
        zip: location.zip,
        phone: location.phone,
        country: location.country,
        county: location.county,
        practicelocationemailaddress: location.practicelocationemailaddress,
        practicelocationwebsite: location.practicelocationwebsite,
        appointmentschedulingwebsite: location.appointmentschedulingwebsite,
        appointmentphonenumber: location.appointmentphonenumber,
        faxnumber: location.faxnumber,
        accessibility: location.accessibility,
        languages: location.languages,
        managerfname: location.managerfname,
        managerlname: location.managerlname,
        managerphone: location.managerphone,
        manageremail: location.manageremail,
      }))
      .filter((location) => {
        // Create a unique key for each location
        const uniqueKey = `${location.street1}-${location.city}`;
        if (uniqueLocations.has(uniqueKey)) {
          return false;
        }
        uniqueLocations.add(uniqueKey);
        return true;
      });

    setPracticeLocations(formattedLocations);
  };

  const handlePracticeSelect = (selectedValue: string, index: number) => {
    const businessName = selectedValue.split('_')[0];

    const selectedPractice = practicesList.find(
      (practice) => practice.legalbusinessname === businessName,
    );

    if (selectedPractice) {
      const fieldsToUpdate = [
        'legalbusinessname',
        'taxid',
        'typeoftaxid',
        'organizationnpi',
      ];

      fieldsToUpdate.forEach((field) => {
        setValue(`practices.${index}.${field}`, selectedPractice[field]);
      });

      setSelectedPractice(selectedPractice);
    }
  };

  const handlePracticeLocationSelect = (
    selectedValue: string,
    index: number,
  ) => {
    const locationDetails = practiceLocations.find(
      (location) => location.value === selectedValue,
    );

    if (locationDetails) {
      const fieldsToUpdate = [
        'street1',
        'city',
        'state',
        'zip',
        'phone',
        'country',
        'county',
        'practicelocationemailaddress',
        'practicelocationwebsite',
        'appointmentschedulingwebsite',
        'appointmentphonenumber',
        'faxnumber',
        'accessibility',
        'languages',
        'managerfname',
        'managerlname',
        'managerphone',
        'manageremail',
      ];

      fieldsToUpdate.forEach((field) => {
        setValue(`practices.${index}.${field}`, locationDetails[field]);
      });
    }
  };

  const fetchPracticesPage = async () => {
    try {
      const response = await httpClient.get(`/api/practices/${memberId}/all`);
      const fetchedPractices = response.data;

      const uniquePractices = Array.from(
        new Map(
          [...practicesList, ...fetchedPractices].map((practice) => [
            practice.legalbusinessname,
            practice,
          ]),
        ).values(),
      );

      const formattedPractices = uniquePractices
        .map((practice: any) => ({
          value: practice.legalbusinessname,
          label: `${practice.legalbusinessname}`,
          searchFields: `${practice.legalbusinessname} ${practice.taxid} ${practice.organizationnpi}`,
        }))
        .filter(
          (item, idx, self) =>
            idx === self.findIndex((t) => t.value === item.value),
        );

      setPracticesList(uniquePractices);
      setAutocompleteData(formattedPractices);
    } catch (error) {
      console.error('Error fetching practices:', error);
    }
  };

  const fetchPractices = async () => {
    try {
      const response = await httpClient.get(`/api/practices/${memberId}`);
      const fetchedPractices = response.data;

      const primaryPractice = fetchedPractices.find(
        (practice: any) => practice.primary,
      );
      const secondaryPractices = fetchedPractices
        .filter((practice: any) => !practice.primary)
        .sort((a: any, b: any) => a.id - b.id);

      const formatPractice = (practice: any) => ({
        ...practice,
        phone: practice.locations[0]?.phone || '',
        street1: practice.locations[0]?.street1 || '',
        street2: practice.locations[0]?.street2 || '',
        city: practice.locations[0]?.city || '',
        state: practice.locations[0]?.state || '',
        zip: practice.locations[0]?.zip || '',
        country: practice.locations[0]?.country || 'US',
        county: practice.locations[0]?.county || '',
        practicelocationemailaddress:
          practice.locations[0]?.practicelocationemailaddress || '',
        practicelocationwebsite:
          practice.locations[0]?.practicelocationwebsite || '',
        appointmentschedulingwebsite:
          practice.locations[0]?.appointmentschedulingwebsite || '',
        appointmentphonenumber:
          practice.locations[0]?.appointmentphonenumber || '',
        faxnumber: practice.locations[0]?.faxnumber || '',
        accessibility: practice.locations[0]?.accessibility || '',
        languages: practice.locations[0]?.languages || [],
        mondaystarttime: practice.locations[0]?.mondaystarttime || '',
        mondayendtime: practice.locations[0]?.mondayendtime || '',
        tuesdaystarttime: practice.locations[0]?.tuesdaystarttime || '',
        tuesdayendtime: practice.locations[0]?.tuesdayendtime || '',
        wednesdaystarttime: practice.locations[0]?.wednesdaystarttime || '',
        wednesdayendtime: practice.locations[0]?.wednesdayendtime || '',
        thursdaystarttime: practice.locations[0]?.thursdaystarttime || '',
        thursdayendtime: practice.locations[0]?.thursdayendtime || '',
        fridaystarttime: practice.locations[0]?.fridaystarttime || '',
        fridayendtime: practice.locations[0]?.fridayendtime || '',
        saturdaystarttime: practice.locations[0]?.saturdaystarttime || '',
        saturdayendtime: practice.locations[0]?.saturdayendtime || '',
        sundaystarttime: practice.locations[0]?.sundaystarttime || '',
        sundayendtime: practice.locations[0]?.sundayendtime || '',
        managerfname: practice.locations[0]?.managerfname || '',
        managerlname: practice.locations[0]?.managerlname || '',
        managerphone: practice.locations[0]?.managerphone || '',
        manageremail: practice.locations[0]?.manageremail || '',
        practice_owner:
          practice.locations[0]?.member_practicelocation[0]?.practice_owner ||
          false,
        practicelimitations: {
          limitGender:
            practice.practicelocations?.[0]?.practicelimitations?.limitGender ||
            false,
          genderType:
            practice.practicelocations?.[0]?.practicelimitations?.genderType ||
            '',
          ageMinimum:
            practice.practicelocations?.[0]?.practicelimitations?.ageMinimum ||
            0,
          ageMaximum:
            practice.practicelocations?.[0]?.practicelimitations?.ageMaximum ||
            0,
          limitAge:
            practice.practicelocations?.[0]?.practicelimitations?.limitAge ||
            false,
          limitOther:
            practice.practicelocations?.[0]?.practicelimitations?.limitOther ||
            false,
          otherLimitations:
            practice.practicelocations?.[0]?.practicelimitations
              ?.otherLimitations || '',
        },
        patientacceptance: {
          acceptAllNewPatients:
            practice.practicelocations?.[0]?.patientacceptance
              ?.acceptAllNewPatients || false,
          acceptNewPatients:
            practice.practicelocations?.[0]?.patientacceptance
              ?.acceptNewPatients || false,
          acceptExistingPatients:
            practice.practicelocations?.[0]?.patientacceptance
              ?.acceptExistingPatients || false,
          acceptMedicarePatients:
            practice.practicelocations?.[0]?.patientacceptance
              ?.acceptMedicarePatients || false,
          acceptMedicaidPatients:
            practice.practicelocations?.[0]?.patientacceptance
              ?.acceptMedicaidPatients || false,
          acceptPhysicianReferrals:
            practice.practicelocations?.[0]?.patientacceptance
              ?.acceptPhysicianReferrals || false,
          referralCircumstances:
            practice.practicelocations?.[0]?.patientacceptance
              ?.referralCircumstances || '',
          referralScreeningQuestions:
            practice.practicelocations?.[0]?.patientacceptance
              ?.referralScreeningQuestions || '',
          variesByHealthPlan:
            practice.practicelocations?.[0]?.patientacceptance
              ?.variesByHealthPlan || false,
        },
      });

      const formattedPrimaryPractice = primaryPractice
        ? formatPractice(primaryPractice)
        : null;
      const formattedSecondaryPractices = secondaryPractices.map(
        (practice: any) => formatPractice(practice),
      );

      const formattedPractices = [
        formattedPrimaryPractice,
        ...formattedSecondaryPractices,
      ].filter(Boolean);

      reset({ practices: formattedPractices });
      setInitialData(formattedPractices);
    } catch (error) {
      console.error('Error fetching practices:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch practices',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const fetchOwnedPractices = async () => {
    try {
      const response = await httpClient.get(
        `/api/members/practices-owned/${memberId}`,
      );
      const data = await response.data;
      setOwnedPractices(data.practicesOwned);
    } catch (error) {
      console.error('Error fetching owned practices', error);
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchPractices();
      fetchOwnedPractices();
    }
  }, [memberId, isSubmitting]);

  useEffect(() => {
    fetchPracticesPage();
  }, []);

  useEffect(() => {
    if (selectedPractice) {
      fetchPracticeLocations(selectedPractice);
    }
  }, [selectedPractice]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    console.log('submit form values', data);

    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
      }));
    };

    const formattedData = formatData(data.practices);

    const createPractice = async (practice: any) => {
      const response = await httpClient.post('/api/practices', {
        memberId,
        practices: [practice],
      });
      return response.data;
    };

    const updatePractice = async (practice: any) => {
      const response = await httpClient.put(`/api/practices/${practice.id}`, {
        memberId,
        practices: [practice],
      });
      return response.data;
    };

    const deletePractice = async (id: number) => {
      await httpClient.delete(`/api/practices/${id}`);
      console.log('delete response');
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(initialData?.map((h: any) => h.id));
    const currentIds = new Set<number>(formattedData.map((h: any) => h.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    console.log('deletedIds', deletedIds);

    const createTasks = formattedData
      .filter((h: any) => !h.id || h.id === 0)
      .map((h: any) => createPractice(h));
    const updateTasks = formattedData
      .filter((h: any) => h.id && h.id > 0 && initialIds.has(h.id))
      .map((h: any) => updatePractice(h));

    const deleteTasks = deletedIds.map((id) => deletePractice(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchPractices();

      notifications.show({
        title: 'Success',
        message: 'Practices updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting practice form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit practice',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOwnerCheckboxChange = (index: number) => {};

  const addEmployee = (
    formType: 'colleague' | 'office_personnel',
    locationId: number,
  ) => {
    setFormType(formType);
    setSelectedMemberId(null);
    setSelectedLocationId(locationId);
    setIsOpen(true);
  };

  const viewMember = (
    formType: 'colleague' | 'office_personnel',
    memberId: number,
  ) => {
    setFormType(formType);
    setSelectedMemberId(memberId);
    setIsOpen(true);
  };

  const deleteMember = async (member: Member, practicelocationid: number) => {
    try {
      modals.openConfirmModal({
        title: `Are you sure you want to delete '${member.firstname} ${member.lastname}'?`,
        labels: { confirm: 'Yes', cancel: 'No' },
        padding: 0,
        onCancel: () => {},
        onConfirm: async () => {
          const response = await httpClient.delete(
            `/api/practices/employee/${practicelocationid}/${member.id || -1}`,
          );
          console.log(response);
          notifications.show({
            title: 'Success',
            message: 'Employee deleted successfully',
            color: 'green',
            position: 'bottom-center',
          });
          fetchPractices();
        },
      });
    } catch (error) {
      console.log('delete response', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete employee record',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <MantineProvider>
      <ModalsProvider>
        <Container>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            {fields.map((item, index) => (
              <Paper key={item.id} shadow="xs" p="md" mt="md" withBorder>
                <Group justify="space-between">
                  <Group>
                    <h3>
                      {index === 0 && initialData && initialData[0]
                        ? 'Primary Practice Location'
                        : `Practice Location ${index + 1}`}
                    </h3>
                    <Button onClick={() => toggleCollapse(index)} size="xs">
                      {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                    </Button>
                    {index !== 0 && (
                      <Button
                        color="red"
                        onClick={() => remove(index)}
                        size="xs"
                      >
                        Remove
                      </Button>
                    )}
                  </Group>
                  <Group>
                    <Autocomplete
                      placeholder="Search by Legal Business Name, Tax ID, or Organization NPI"
                      value={practiceSearchTerms[index]}
                      onChange={(value) => {
                        handlePracticeSearchChange(value, index);
                        handlePracticeSelect(value, index);
                      }}
                      data={autocompleteData}
                      filter={({ options, search }) => {
                        const searchLower = search.toLowerCase().trim();
                        return options.filter((item) => {
                          const option = item as { searchFields?: string };
                          return option.searchFields
                            ?.toLowerCase()
                            .includes(searchLower);
                        });
                      }}
                    />
                  </Group>
                </Group>
                <Collapse in={!collapsedIndices[index]}>
                  <Group justify="flex-end">
                    <Controller
                      name={`practices.${index}.practice_owner`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          label="I own this practice location."
                          checked={field.value}
                          onChange={(event) =>
                            field.onChange(event.currentTarget.checked)
                          }
                        />
                      )}
                    />
                  </Group>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Legal Business Name (as it appears on the W-9)"
                        {...register(`practices.${index}.legalbusinessname`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Tax ID"
                        {...register(`practices.${index}.taxid`)}
                      />
                    </Grid.Col>

                    <Grid.Col span={6}>
                      <Controller
                        name={`practices.${index}.typeoftaxid`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Type of Tax ID"
                            data={[
                              { value: 'Group', label: 'Group' },
                              { value: 'Individual', label: 'Individual' },
                            ]}
                            clearable
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Organization (Type 2) NPI"
                        {...register(`practices.${index}.organizationnpi`)}
                      />
                    </Grid.Col>
                  </Grid>

                  <Paper shadow="xs" p="sm" withBorder mt="sm">
                    <Accordion
                      onChange={(value) =>
                        setOpenPracticeLocationAccordion(
                          value === 'practice-location-info',
                        )
                      }
                    >
                      <Accordion.Item value="practice-location-info">
                        <Accordion.Control>
                          <Group justify="space-between">
                            <h4
                              style={{
                                margin: 0,
                              }}
                            >
                              Practice Location Information
                            </h4>
                            {openPracticeLocationAccordion && (
                              <Autocomplete
                                mr={20}
                                placeholder="Search by Address"
                                value={locationSearchTerms[index]}
                                onChange={(value) => {
                                  handleLocationSearchChange(value, index);
                                  handlePracticeLocationSelect(value, index);
                                }}
                                data={practiceLocations}
                                filter={({ options, search }) => {
                                  const searchLower = search
                                    .toLowerCase()
                                    .trim();
                                  return options.filter((item) => {
                                    const option = item as {
                                      searchFields?: string;
                                    };
                                    return option.searchFields
                                      ?.toLowerCase()
                                      .includes(searchLower);
                                  });
                                }}
                                onClick={(e) => e.stopPropagation()}
                              />
                            )}
                          </Group>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Grid>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.phone`}
                                control={control}
                                render={({ field }) => (
                                  <Input.Wrapper label="Phone">
                                    <Input
                                      component={IMaskInput}
                                      {...field}
                                      mask="(000) 000-0000"
                                      placeholder="(XXX) XXX-XXXX"
                                    />
                                  </Input.Wrapper>
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label={'\u0405treet 1'}
                                {...register(`practices.${index}.street1`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label={'\u0405treet 2'}
                                {...register(`practices.${index}.street2`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="City"
                                {...register(`practices.${index}.city`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.state`}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    label="State"
                                    data={STATES_ARRAY.map((state) => ({
                                      value: state,
                                      label: state,
                                    }))}
                                    clearable
                                    searchable
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="ZIP"
                                {...register(`practices.${index}.zip`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.country`}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    label="Country"
                                    data={[
                                      { value: 'US', label: 'United States' },
                                    ]}
                                    clearable
                                    searchable
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="County"
                                {...register(`practices.${index}.county`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Practice Location Email Address"
                                {...register(
                                  `practices.${index}.practicelocationemailaddress`,
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Practice Location Website"
                                {...register(
                                  `practices.${index}.practicelocationwebsite`,
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Appointment Scheduling Website"
                                {...register(
                                  `practices.${index}.appointmentschedulingwebsite`,
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Appointment Phone Number"
                                {...register(
                                  `practices.${index}.appointmentphonenumber`,
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.faxnumber`}
                                control={control}
                                render={({ field }) => (
                                  <Input.Wrapper label="Fax Number">
                                    <Input
                                      component={IMaskInput}
                                      {...field}
                                      mask="(000) 000-0000"
                                      placeholder="(XXX) XXX-XXXX"
                                    />
                                  </Input.Wrapper>
                                )}
                              />
                            </Grid.Col>
                          </Grid>
                          <Controller
                            name={`practices.${index}.languages`}
                            control={control}
                            render={({ field }) => (
                              <MultiSelect
                                {...field}
                                label="Languages"
                                data={LANGUAGES.map((language) => ({
                                  value: language,
                                  label: language,
                                }))}
                                searchable
                                clearable
                                value={field.value || []}
                              />
                            )}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion>
                      <Accordion.Item value="practice-accessibility">
                        <Accordion.Control>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            Accessibility
                          </h4>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <PracticeLocationAccessibilityForm
                            practiceIndex={index}
                            practiceLocationIndex={0}
                            control={control}
                            register={register}
                            loading={isSubmitting}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion>
                      <Accordion.Item value="office-manager-info">
                        <Accordion.Control>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            Office Manager Contact Information
                          </h4>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Grid>
                            <Grid.Col span={6}>
                              <TextInput
                                label="First Name"
                                {...register(`practices.${index}.managerfname`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Last Name"
                                {...register(`practices.${index}.managerlname`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.managerphone`}
                                control={control}
                                render={({ field }) => (
                                  <Input.Wrapper label="Phone">
                                    <Input
                                      component={IMaskInput}
                                      {...field}
                                      mask="(000) 000-0000"
                                      placeholder="(XXX) XXX-XXXX"
                                    />
                                  </Input.Wrapper>
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Email"
                                {...register(`practices.${index}.manageremail`)}
                              />
                            </Grid.Col>
                          </Grid>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item value="office-hours">
                        <Accordion.Control>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            Office Hours
                          </h4>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Grid>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.mondaystarttime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Monday Start Time"
                                  />
                                )}
                              />
                              <Controller
                                name={`practices.${index}.mondayendtime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Monday End Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.tuesdaystarttime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Tuesday Start Time"
                                  />
                                )}
                              />
                              <Controller
                                name={`practices.${index}.tuesdayendtime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Tuesday End Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.wednesdaystarttime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Wednesday Start Time"
                                  />
                                )}
                              />
                              <Controller
                                name={`practices.${index}.wednesdayendtime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Wednesday End Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.thursdaystarttime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Thursday Start Time"
                                  />
                                )}
                              />
                              <Controller
                                name={`practices.${index}.thursdayendtime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Thursday End Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.fridaystarttime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Friday Start Time"
                                  />
                                )}
                              />
                              <Controller
                                name={`practices.${index}.fridayendtime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Friday End Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.saturdaystarttime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Saturday Start Time"
                                  />
                                )}
                              />
                              <Controller
                                name={`practices.${index}.saturdayendtime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Saturday End Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.sundaystarttime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Sunday Start Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`practices.${index}.sundayendtime`}
                                control={control}
                                render={({ field }) => (
                                  <TimeInput
                                    {...field}
                                    label="Sunday End Time"
                                  />
                                )}
                              />
                            </Grid.Col>
                          </Grid>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item value="practice-location-affiliation">
                        <Accordion.Control>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            Affiliation
                          </h4>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <PracticeLocationAffliationForm
                            control={control}
                            register={register}
                            practiceIndex={index}
                            practiceLocationIndex={0}
                            loading={isSubmitting}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item value="practicelimitations">
                        <Accordion.Control>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <h4 style={{ margin: 0 }}>Practice Limitations</h4>
                            <Tooltip
                              label="A limitation is any restriction you have set on the gender or age of your patient population"
                              position="right"
                              withArrow
                            >
                              <IconInfoCircle
                                size={16}
                                style={{ color: 'gray' }}
                              />
                            </Tooltip>
                          </div>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <PracticeLimitations
                            index={index}
                            control={control}
                            getValues={getValues}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item value="patientacceptance">
                        <Accordion.Control>
                          <h4 style={{ margin: 0 }}>Patients</h4>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <PatientSection index={index} control={control} />
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Paper>

                  {getValues(`practices`)[index].practice_owner && (
                    <Paper shadow="xs" p="sm" withBorder mt="sm">
                      <Title size="md" mb={0} mt={20} ta="center">
                        Manage Practice Employees
                      </Title>

                      <Group justify="space-between">
                        <Title size="md" mb={0} mt={20}>
                          Colleague
                        </Title>
                        <Table border={1} cellPadding="10">
                          <Thead>
                            <Tr>
                              {/* <Th>ID</Th> */}
                              <Th>Type</Th>
                              <Th>First Name</Th>
                              <Th>Last Name</Th>
                              <Th>Email</Th>
                              <Th>NPI</Th>
                              <Th></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {getValues('practices')?.[0]
                              ?.practicelocations?.[
                                index
                              ]?.member_practicelocation?.filter(
                                (m) => m.employeetype === 'colleague',
                              )
                              .map(
                                (
                                  {
                                    members: member,
                                    employeetype,
                                    practicelocationid,
                                  },
                                  index,
                                ) => {
                                  return (
                                    <Tr key={index}>
                                      {/* <Td>{member.id}</Td> */}
                                      <Td>{member.providertype}</Td>
                                      <Td>{member.firstname}</Td>
                                      <Td>{member.lastname}</Td>
                                      <Td>{member.email}</Td>
                                      <Td>{member.npi}</Td>
                                      <Td width={'150px'}>
                                        <Button
                                          mr="sm"
                                          onClick={() => {
                                            viewMember('colleague', member.id);
                                          }}
                                        >
                                          <IconPencil size={20} />
                                        </Button>
                                        <Button
                                          color="red"
                                          onClick={() => {
                                            deleteMember(
                                              member,
                                              practicelocationid,
                                            );
                                          }}
                                        >
                                          <IconX size={20} />
                                        </Button>
                                      </Td>
                                    </Tr>
                                  );
                                },
                              )}
                          </Tbody>
                        </Table>

                        <Button
                          mr="xs"
                          mb={10}
                          onClick={() =>
                            addEmployee(
                              'colleague',
                              getValues('practices')?.[index]
                                ?.practicelocations?.[0]?.id,
                            )
                          }
                          disabled={
                            !getValues('practices')?.[index]
                              ?.practicelocations?.[0]?.id
                          }
                        >
                          Add Colleague
                        </Button>
                      </Group>
                      <Group justify="space-between">
                        <Title size="md" mb={0} mt={20}>
                          Office Personnel
                        </Title>
                        <Table border={1} cellPadding="10">
                          <Thead>
                            <Tr>
                              {/* <th>ID</th> */}
                              {/* <Th>Type</Th> */}
                              <Th>First Name</Th>
                              <Th>Last Name</Th>
                              <Th>Email</Th>
                              <Th>Phone</Th>
                              <Th></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {getValues('practices')?.[0]
                              ?.practicelocations?.[
                                index
                              ]?.member_practicelocation?.filter(
                                (m) => m.employeetype === 'office_personnel',
                              )
                              .map(
                                (
                                  {
                                    members: member,
                                    employeetype,
                                    practicelocationid,
                                  },
                                  index,
                                ) => {
                                  return (
                                    <Tr key={index}>
                                      {/* <Td>{member.id}</Td> */}
                                      {/* <Td>{member.providertype}</Td> */}
                                      <Td>{member.firstname}</Td>
                                      <Td>{member.lastname}</Td>
                                      <Td>{member.email}</Td>
                                      <Td>{member.mobilephone}</Td>
                                      <Td width={'150px'}>
                                        <Button
                                          mr="sm"
                                          onClick={() => {
                                            viewMember(
                                              'office_personnel',
                                              member.id,
                                            );
                                          }}
                                        >
                                          <IconPencil size={20} />
                                        </Button>
                                        <Button
                                          color="red"
                                          onClick={() => {
                                            deleteMember(
                                              member,
                                              practicelocationid,
                                            );
                                          }}
                                        >
                                          <IconX size={20} />
                                        </Button>
                                      </Td>
                                    </Tr>
                                  );
                                },
                              )}
                          </Tbody>
                        </Table>
                        <Button
                          mr="xs"
                          mb={10}
                          onClick={() =>
                            addEmployee(
                              'office_personnel',
                              getValues('practices')?.[index]
                                ?.practicelocations?.[0]?.id,
                            )
                          }
                          disabled={
                            !getValues('practices')?.[index]
                              ?.practicelocations?.[0]?.id
                          }
                        >
                          Add Office Personnel
                        </Button>
                      </Group>
                      {!getValues('practices')?.[index]?.practicelocations?.[0]
                        ?.id && (
                        <i style={{ color: 'red', fontSize: '0.8em' }}>
                          Save this practice location first to add employees
                        </i>
                      )}
                    </Paper>
                  )}
                </Collapse>
              </Paper>
            ))}
            <Group mt="md">
              <Button
                onClick={() =>
                  append({
                    legalbusinessname: '',
                    typeoftaxid: '',
                    organizationnpi: '',
                    phone: '',
                    street1: '',
                    street2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: 'US',
                    county: '',
                    practicelocationemailaddress: '',
                    practicelocationwebsite: '',
                    appointmentschedulingwebsite: '',
                    appointmentphonenumber: '',
                    faxnumber: '',
                    accessibility: '',
                    languages: [],
                    mondaystarttime: '',
                    mondayendtime: '',
                    tuesdaystarttime: '',
                    tuesdayendtime: '',
                    wednesdaystarttime: '',
                    wednesdayendtime: '',
                    thursdaystarttime: '',
                    thursdayendtime: '',
                    fridaystarttime: '',
                    fridayendtime: '',
                    saturdaystarttime: '',
                    saturdayendtime: '',
                    sundaystarttime: '',
                    sundayendtime: '',
                    managerfname: '',
                    managerlname: '',
                    managerphone: '',
                    manageremail: '',
                    primary: 'N',
                    practice_owner: false,
                    practicelimitations: {
                      limitGender: false,
                      genderType: '',
                      limitAge: false,
                      ageMinimum: 0,
                      ageMaximum: 0,
                      limitOther: false,
                      otherLimitations: '',
                    },
                    patientacceptance: {
                      acceptAllNewPatients: false,
                      acceptNewPatients: false,
                      acceptExistingPatients: false,
                      acceptMedicarePatients: false,
                      acceptMedicaidPatients: false,
                      acceptPhysicianReferrals: false,
                      referralCircumstances: '',
                      referralScreeningQuestions: '',
                      variesByHealthPlan: false,
                    },
                  })
                }
              >
                Add Practice
              </Button>
            </Group>
            <Group mt="md">
              <Button type="submit" loading={isSubmitting}>
                Save
              </Button>
            </Group>
          </form>
          <PracticeLocationEmployeeModal
            formType={formType}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedLocationId={selectedLocationId}
            selectedMemberId={selectedMemberId}
            cb={fetchPractices}
          />
          <br />
          {/* <pre>{JSON.stringify(getValues(), null, 2)}</pre> */}
        </Container>
      </ModalsProvider>
    </MantineProvider>
  );
};

export default PracticeForm;
