import React, { useState, useMemo } from 'react';
import {
  Modal,
  Button,
  Checkbox,
  Text,
  Group,
  List,
  Title,
  Textarea,
} from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import { PLANS_STATIC } from '../../types/plan.types';
import { useAuth } from '../hooks/useAuth';
import {
  OrganizationMember,
  ExtendedOrganizationMember,
} from '../../types/subscribermember.types';
import { notifications } from '@mantine/notifications';

interface CompleteRegistrationModalProps {
  opened: boolean;
  onClose: () => void;
}

const CompleteMemberRegistrationModal: React.FC<
  CompleteRegistrationModalProps
> = ({ opened, onClose }) => {
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();

  const { organizationId } = useAuth();
  const { member } = useInsuranceMemberContext();
  const [members, setMembers] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const fetchMembers = async () => {
    try {
      const response = await httpClient.get(`/api/subscriber/organization/`);
      const fetchedMembers: OrganizationMember[] = response.data;

      const premiumNumbers = await httpClient.get(`/api/premium/`);
      if (
        premiumNumbers.data.length > 0 &&
        JSON.stringify(premiumNumbers.data[0]) !== JSON.stringify(paymentInfo)
      ) {
        setPaymentInfo(premiumNumbers.data[0]);
      }
    } catch (error) {
      console.error('Error fetching members:', error);
    }

    try {
      const response = await httpClient.get(
        `/api/subscriber/${member.id}/family`,
      );
      const fetchedMembers: ExtendedOrganizationMember[] = response.data || [];

      const sortedMembers = fetchedMembers
        .filter((member) => member.enrollmentstatus !== 'Removed')
        .sort((a, b) => {
          const relationshipOrder = ['Primary', 'Spouse', 'Child'];
          return (
            relationshipOrder.indexOf(a.subscriberrelationship) -
            relationshipOrder.indexOf(b.subscriberrelationship)
          );
        });

      setMembers(sortedMembers);
    } catch (error) {}
  };

  useMemo(() => {
    if (organizationId && !member) {
      fetchMembers();
    }
  }, [organizationId]);

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.currentTarget.checked);
  };

  const completeRegistration = async () => {
    setLoading(true);
    try {
      await httpClient.put(
        `/api/subscriber/${member.id}/complete-registration`,
      );
      onClose(); // Close the modal after successful registration
    } catch (error) {
      console.error('Error completing registration:', error);
      // Handle the error appropriately (e.g., show a notification)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Title order={1}>Registration Summary</Title>}
      centered
      size="xl"
    >
      <Title order={3}>Personal Information</Title>
      <List mb="xl" listStyleType="none">
        <List.Item>
          Name:{' '}
          <strong>
            {member?.firstname} {member?.lastname}
          </strong>
        </List.Item>
        <List.Item>
          Sex: <strong>{member?.sex === 'M' ? 'Male' : 'Female'}</strong>
        </List.Item>
        <List.Item>
          Date of Birth:{' '}
          <strong>
            {new Date(member?.birthdate)?.toLocaleDateString('en-US')}
          </strong>
        </List.Item>
        <List.Item>
          Contact:{' '}
          <strong>
            {member?.phone} / {member?.email}
          </strong>
        </List.Item>
        <List.Item>
          Address:{' '}
          <strong>
            {member?.street1} {member?.street2} {member?.city}, {member?.state}{' '}
            {member?.zip}
          </strong>
        </List.Item>
      </List>

      <Title order={3}>Family Members</Title>
      <List mb="xl">
        {members?.map((member) => {
          return (
            member?.subscriberrelationship && (
              <List.Item key={member.id}>
                {member?.subscriberrelationship}{' '}
                <strong>
                  {member?.firstname} {member?.lastname}
                </strong>
              </List.Item>
            )
          );
        })}
      </List>

      <Title order={3}>Plan & Payment</Title>
      <List mb="xl">
        {PLANS_STATIC.filter((plan) => Number(plan.id) === member?.planid).map(
          (plan) => (
            <span key={plan.id + '-1'}>
              <List.Item color="dimmed">
                <strong>{plan.name}</strong>
              </List.Item>
              <List.Item>
                Total Premium{' '}
                <strong>
                  {Math.round(paymentInfo?.totalPremium * 100) / 100}
                </strong>
              </List.Item>
              <List.Item color="dimmed">
                Coinsurance: {plan.inn[0]} / {plan.oon[0]}
              </List.Item>
              {/* <List.Item color="dimmed">
                INN Deductible (Individual/Family): {plan.inn[1]}
              </List.Item>
              <List.Item color="dimmed">
                OON Deductible (Individual/Family): {plan.oon[1]}
              </List.Item> */}
            </span>
          ),
        )}
      </List>

      <Title order={3}>Privacy & Policy</Title>
      <Text mt="sm" mb="sm">
        Here is some placeholder text for the privacy policy
      </Text>
      <Text mb="xl">
        <Textarea
          autosize
          minRows={6}
          maxRows={6}
          value={`Please review it carefully.`}
        />
      </Text>
      <Group mt="md">
        <Checkbox
          label="I agree to the privacy policy"
          checked={agreed}
          onChange={handleAgreeChange}
        />
        <Button disabled={!agreed || loading} onClick={completeRegistration}>
          {loading ? 'Completing...' : 'Complete Registration'}
        </Button>
      </Group>
    </Modal>
  );
};

export default CompleteMemberRegistrationModal;
