import React from 'react';
import {
  Grid,
  Checkbox,
  Radio,
  NumberInput,
  Textarea,
  Tooltip,
} from '@mantine/core';
import { Controller, Control, UseFormGetValues } from 'react-hook-form';
import { IconInfoCircle } from '@tabler/icons-react';

interface PracticeLimitationsProps {
  index: number;
  control: Control<any>;
  getValues: UseFormGetValues<any>;
}

const PracticeLimitations: React.FC<PracticeLimitationsProps> = ({
  index,
  control,
  getValues,
}) => {
  return (
    <Grid>
      <Grid.Col span={4}>
        <Controller
          name={`practices.${index}.practicelimitations.limitGender`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              {...field}
              label="Gender"
              checked={field.value}
              onChange={(event) => field.onChange(event.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>

      <Grid.Col span={4}>
        <Controller
          name={`practices.${index}.practicelimitations.limitAge`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              {...field}
              label="Age"
              checked={field.value}
              onChange={(event) => field.onChange(event.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>

      <Grid.Col span={4}>
        <Controller
          name={`practices.${index}.practicelimitations.limitOther`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              {...field}
              label="Other"
              checked={field.value}
              onChange={(event) => field.onChange(event.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>

      {getValues(`practices.${index}.practicelimitations.limitGender`) && (
        <Grid.Col span={12}>
          <Controller
            name={`practices.${index}.practicelimitations.genderType`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Radio.Group
                {...field}
                label={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                    Gender Limitations
                  </span>
                }
              >
                <Radio mt={10} value="female" label="Female only" />
                <Radio mt={10} value="male" label="Male only" />
              </Radio.Group>
            )}
          />
        </Grid.Col>
      )}

      {getValues(`practices.${index}.practicelimitations.limitAge`) && (
        <>
          <Grid.Col span={6}>
            <Controller
              name={`practices.${index}.practicelimitations.ageMinimum`}
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ color: 'red', marginRight: '4px' }}>
                        *
                      </span>
                      Age Minimum
                    </span>
                  }
                  min={0}
                  max={120}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name={`practices.${index}.practicelimitations.ageMaximum`}
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <div style={{ position: 'relative' }}>
                  <NumberInput
                    {...field}
                    label={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ color: 'red', marginRight: '4px' }}>
                            *
                          </span>
                          Age Maximum
                        </span>
                        <Tooltip
                          label="If you have no age maximum, enter '0'."
                          position="right"
                          withArrow
                        >
                          <IconInfoCircle size={16} style={{ color: 'gray' }} />
                        </Tooltip>
                      </div>
                    }
                    min={0}
                    max={120}
                  />
                </div>
              )}
            />
          </Grid.Col>
        </>
      )}

      {getValues(`practices.${index}.practicelimitations.limitOther`) && (
        <Grid.Col span={12}>
          <Controller
            name={`practices.${index}.practicelimitations.otherLimitations`}
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                label="Other Limitations"
                placeholder="Please specify other limitations"
              />
            )}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};

export default PracticeLimitations;
