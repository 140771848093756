import React from 'react';
import { Grid, Text, Radio, Textarea } from '@mantine/core';
import { Controller, Control } from 'react-hook-form';

interface PatientSectionProps {
  index: number;
  control: Control<any>;
}

const PatientSection: React.FC<PatientSectionProps> = ({ index, control }) => {
  const renderRadioGroup = (
    name: string,
    label: string,
    required: boolean = true,
  ) => (
    <Controller
      name={`practices.${index}.patientacceptance.${name}`}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <Radio.Group
          {...field}
          value={field.value?.toString()}
          onChange={(value) => field.onChange(value === 'true')}
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {label}
              {required && (
                <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
              )}
            </span>
          }
        >
          <Radio mt="xs" value="true" label="Yes" />
          <Radio mt="xs" value="false" label="No" />
        </Radio.Group>
      )}
    />
  );

  return (
    <>
      <Text size="sm" c="dimmed" mb="md">
        The questions in this section pertain to your general activity and
        preferences at this location. They are not specific to your activity in
        relation to any health plan.
      </Text>

      <Grid>
        <Grid.Col span={12}>
          {renderRadioGroup(
            'acceptAllNewPatients',
            'Do you accept all new patients at this location?',
          )}
        </Grid.Col>

        <Grid.Col span={12}>
          {renderRadioGroup(
            'acceptNewPatients',
            'Do you accept new patients at this practice location?',
          )}
        </Grid.Col>

        <Grid.Col span={12}>
          {renderRadioGroup(
            'acceptExistingPatients',
            'Do you accept existing patients with change of payor at this location?',
          )}
        </Grid.Col>

        <Grid.Col span={12}>
          {renderRadioGroup(
            'acceptMedicarePatients',
            'Do you accept new Medicare patients at this location?',
          )}
        </Grid.Col>

        <Grid.Col span={12}>
          {renderRadioGroup(
            'acceptMedicaidPatients',
            'Do you accept new Medicaid patients at this location?',
          )}
        </Grid.Col>

        <Grid.Col span={12}>
          {renderRadioGroup(
            'acceptPhysicianReferrals',
            'Do you accept new patients from physician referrals (i.e., referring letter) at this location?',
          )}
        </Grid.Col>

        <Grid.Col span={12}>
          <Controller
            name={`practices.${index}.patientacceptance.referralCircumstances`}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Textarea
                {...field}
                label="Under what circumstances do you accept referral?"
                minRows={3}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Controller
            name={`practices.${index}.patientacceptance.referralScreeningQuestions`}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Textarea
                {...field}
                label="What questions can a patient be asked to determine appropriateness of referral?"
                minRows={3}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          {renderRadioGroup(
            'variesByHealthPlan',
            'Does the above information vary by health plan?',
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PatientSection;
