import React from 'react';
import { Button, Group, Modal, TextInput } from '@mantine/core';

interface EditGroupNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  editedGroupName: string;
  setEditedGroupName: (name: string) => void;
  isUpdating: boolean;
  handleEditGroupName: (conversationId: number) => void;
  editingConversationId: number;
}

const EditGroupNameModal: React.FC<EditGroupNameModalProps> = ({
  isOpen,
  onClose,
  editedGroupName,
  setEditedGroupName,
  isUpdating,
  handleEditGroupName,
  editingConversationId,
}) => {
  return (
    <Modal
      opened={isOpen}
      centered
      onClose={onClose}
      title="Edit Group Name"
    >
      <TextInput
        value={editedGroupName}
        onChange={(e) => setEditedGroupName(e.target.value)}
        placeholder="Enter new group name"
      />
      <Group justify="space-between">
        <Button
          color="red"
          onClick={onClose}
          mt="md"
        >
          Cancel
        </Button>
        <Button
          loading={isUpdating}
          disabled={isUpdating}
          onClick={() => handleEditGroupName(editingConversationId)}
          mt="md"
        >
          Save
        </Button>
      </Group>
    </Modal>
  );
};

export default EditGroupNameModal;