import React, { useEffect, useState } from 'react';
import { Avatar, Group, Menu, ActionIcon } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconReload } from '@tabler/icons-react';
import { IconUser } from '@tabler/icons-react';
import useHttpClient from './hooks/useHttpClient';

interface AvatarProps {
  memberId?: number | null;
  children?: React.ReactNode;
  [key: string]: any; // Allow additional props
}

export function RyzeAvatar({ memberId, children, ...props }: AvatarProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(true);
  const imageUrl =
    process.env.REACT_APP_PUBLIC_S3_BUCKET + 'avatars/' + memberId + '.jpg';

  return (
    <Avatar
      alt="Avatar"
      color="blue"
      radius={80}
      style={{ cursor: 'pointer' }}
      src={isImageLoaded ? imageUrl : undefined}
      onError={() => setIsImageLoaded(false)}
      {...props}
    >
      {children}
    </Avatar>
  );
}

function AvatarUploader({ memberId }: { memberId?: number | null }) {
  const [image, setImage] = useState(null);
  const httpClient = useHttpClient();
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleDrop = (files) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('avatar', file);

    httpClient
      .post('/api/upload/upload-avatar', formData)
      .then((response) => {
        setImage(response.data.fileUrl);
        setIsImageLoaded(true);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  };

  const handleRandom = async () => {
    try {
      httpClient
        .post('/api/upload/random-avatar')
        .then((response) => {
          setImage(response.data.fileUrl + '?ts=' + new Date().getTime());
          setIsImageLoaded(true);
        })
        .catch((error) => {
          console.error('Error updating avatar:', error);
        });
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
  };

  useEffect(() => {
    setImage(
      process.env.REACT_APP_PUBLIC_S3_BUCKET + 'avatars/' + memberId + '.jpg',
    );
  }, [memberId]);

  return (
    <Group mx="auto">
      <div style={{ position: 'relative', width: '180px', margin: 'auto' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: 99999,
          }}
        >
          <Menu>
            <ActionIcon variant="primary" size="lg" onClick={handleRandom}>
              <IconReload size={24} />
            </ActionIcon>
          </Menu>
        </div>
        <Dropzone
          onDrop={handleDrop}
          accept={['image/png', 'image/jpeg']}
          maxSize={5 * 1024 ** 2} // 5MB max size
          style={{ position: 'relative' }}
          mx="auto"
        >
          <Avatar
            alt="Avatar"
            radius={90}
            size={180}
            style={{ cursor: 'pointer' }}
            mx="auto"
            src={isImageLoaded ? image : undefined}
            onError={() => setIsImageLoaded(false)}
          >
            {!image && <IconUser size={100} />}
          </Avatar>
        </Dropzone>
      </div>
    </Group>
  );
}

export default AvatarUploader;
